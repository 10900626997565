.force {
  .section__inner {
    overflow-x: unset;
  }

  &__title {
    grid-area: 1 / 2 / 2 / 5;
    margin-block-end: 120px;
  }

  &__pic-1 {
    position: relative;
    display: flex;
    grid-area: 2 / 2 / 4 / 4;
    min-height: 430px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__pic-2 {
    position: relative;
    display: flex;
    grid-area: 1 / 5 / 3 / -1;
  }

  &__lead {
    grid-area: 3 / 5 / 5 / -1;
    padding-inline-end: 10px;
    font-size: clamp(20px, 1.5vw, 25px);
    line-height: 1.4;
  }

  &__list {
    display: grid;
    grid-area: 5 / 1 / 6 / -1;
    grid-template-areas: '. a b c d e';
    grid-template-columns: repeat(var(--columns), 1fr);

    padding-block: 50px 0;

    border-top: 2px solid $color-gray-2;
  }

  &__item {
    position: relative;
    padding-block: 30px;
    padding-inline: 20px;
    font-size: clamp(14px, 1.3vw, 18px);

    &-inner {
      position: relative;
    }

    &-icon {
      margin-block-end: 30px;
    }

    &:nth-child(1) {
      grid-area: a;
    }

    &:nth-child(2) {
      grid-area: b;
    }

    &:nth-child(3) {
      grid-area: c;
    }

    &:nth-child(4) {
      grid-area: d;
    }

    &:nth-child(5) {
      grid-area: e;
    }
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) .force__item-inner {
        transition-delay: #{(($i) * 0.2)}s;
      }
    }
  }

  &__release {
    display: flex;
    grid-area: 4 / 5 / 5 / -1;
    align-items: center;

    &-pic {
      flex-shrink: 0;
      width: 50%;
      background-color: $color-yellow;
    }

    &-link {
      width: 40%;
      padding-inline: 10px;
      text-wrap: balance;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / 3;
      margin-block-end: 80px;
    }

    &__pic-1 {
      grid-area: 2 / 1 / 5 / 3;
      height: 100%;
      min-height: unset;

      &::before {
        left: calc(var(--p-inline) * -1);
      }
    }

    &__pic-2 {
      grid-area: 1 / 3 / 4 / 5;
      height: 90%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__release {
      grid-area: 4 / 3 / 5 / 5;

      &-link {
        width: 35%;
      }
    }

    &__list {
      grid-area: 6 / 1 / 7 / -1;
      grid-template-areas:
        '. a b c'
        '. d e .';
    }

    &__item {
      padding-block: 20px;
      padding-inline: 10px;

      &-icon {
        height: 45px;
        margin-block-end: 15px;

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  @include tzar-breakpoint-down(md) {
    &__title {
      margin-block-end: 60px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    .section__inner {
      display: flex;
      flex-direction: column;
      padding-block: 70px;
    }

    &__title {
      margin-block-end: 16px;
    }

    &__pic-1 {
      order: 10;
    }

    &__pic-2 {
      margin-block-end: 20px;
    }

    &__release {
      &-link {
        width: 45%;
      }
    }

    &__list {
      display: flex;
      flex-flow: row wrap;
      padding-block: 30px;
    }

    &__item {
      width: 50%;
      padding-block: 25px;

      &-icon {
        height: 40px;
      }
    }

    .pic__wrap {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

.force__title span {
  &:nth-child(2) {
    translate: 10% -7px;
  }
  @include tzar-breakpoint-down(sm) {
    &:nth-child(2) {
      translate: 30px 0;
      padding-right: 30px;
    }
  }
  @include tzar-breakpoint-down(чы) {
    &:nth-child(2) {
      translate: 20px 0;
    }
  }
}
