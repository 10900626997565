.footer {
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  padding-block: 26px;
  border-top: 1px solid $color-gray-2;

  &__copyright {
    grid-area: 1 / 2 / 2 / 3;
  }

  &__social {
    grid-area: 1 / 4 / 2 / 5;
  }

  &__contacts {
    display: flex;
    grid-area: 1 / -2 / 2 / -1;
    flex-direction: column;
    gap: 3px;

    text-align: end;
  }

  address,
  p,
  a {
    margin: 0;

    font-size: clamp(16px, 1.6vw, 22px);
    font-style: normal;
    line-height: 1;
    color: #b6b6b6;
  }

  a {
    @include hover {
      color: $color-orange;
    }
  }
  @include tzar-breakpoint-down(xl) {
    address,
    p,
    a {
      font-size: 18px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__copyright {
      grid-area: 1 / 1 / 2 / 2;
    }

    &__social {
      grid-area: 1 / 3 / 2 / 4;
    }

    &__contacts {
      grid-area: 1 / 4 / 2 / -1;
    }
  }
  @include tzar-breakpoint-down(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__social {
      display: flex;
      justify-content: end;
      width: 50%;
    }

    &__contacts {
      order: -1;
      width: 50%;
      text-align: start;
    }

    &__copyright {
      order: 1;

      width: 100%;
      margin-top: 40px !important;
      margin-left: auto !important;

      text-align: end;
      text-wrap: balance;
    }

    address,
    p,
    a {
      font-size: 16px;
    }
  }
}

.social {
  &__list {
    display: flex;
    gap: 28px;
    align-items: center;
  }

  &__item {
    display: flex;
  }

  &__link {
    @include hover {
      svg path {
        fill: $color-orange;
      }
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__list {
      gap: 16px;
    }
  }
}
