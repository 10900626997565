a {
  --link-color: $color-text;
  --link-color_visited: $color-text;
  --link-color_hover: $color-text;
  --link-color_disabled: $color-text;

  color: var(--link-color);
  text-decoration: none;

  &:visited {
    color: var(--link-color_visited);
  }

  /** Залипает&:focus {
		outline: 1px dotted lightblue;
	} */

  /** media hover */
  &:hover,
  &:active {
    color: var(--link-color_hover);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: var(--link-color_disabled);
  }
}

.external-link {
  --external-link-color: var(--var_3);
  --external-link-color_visited: var(--var_5);
  --external-link-color_hover: var(--var_1);
  --external-link-color_disabled: var(--var_4);

  cursor: alias;
  color: var(--external-link-color);
  text-decoration: underline;

  &:visited {
    color: var(--external-link-color_visited);
  }

  /** Залипает&:focus {
		outline: 1px dotted lightblue;
	} */

  &:hover,
  &:active {
    color: var(--external-link-color_hover);
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: var(--external-link-color_visited);
  }
}

button {
  /** Alpha через переменные? */
  --btn-color: var(--var_6);
  --btn-bg: var(--var_1);
  --btn-border: var(--var_6);
  --btn-color_hover: var(--var_7);
  --btn-bg_hover: var(--var_8);
  --btn-border_hover: var(--var_7);
  --btn-color_disabled: var(--var_1); // todo
  --btn-bg_disabled: var(--var_5);
  --btn-border_disabled: var(--var_1); // todo

  cursor: pointer;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  border: 2px solid var(--btn-border);

  /** Залипает&:focus {
		outline: 1px dotted lightblue;
	} */

  &:hover,
  &:active {
    color: var(--btn-color_hover);
    background-color: var(--btn-bg_hover);
    border-color: var(--btn-border_hover);
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    background-color: var(--btn-bg_disabled);
  }
}

.button-light {
  --btn-color: rgb(95 255 255);
  --btn-bg: #979393;
  --btn-border: #000;
  --btn-color_hover: #979393;
  --btn-bg_hover: rgb(95 255 255);

  // --btn-border_hover: ;

  --btn-color_disabled: rgb(95 255 255);
  --btn-bg_disabled: #979393;
  --btn-border_disabled: #000;

  &:disabled,
  &.disabled {
    opacity: 0.4;
  }
}

.button-dark {
  --btn-color: #fff;
  --btn-bg: #000;
  --btn-border: #fff;
  --btn-color_hover: #000;
  --btn-bg_hover: #fff;
  --btn-border_hover: #000;
  --btn-color_disabled: #fff;
  --btn-bg_disabled: #000;
  --btn-border_disabled: #fff;

  &:disabled,
  &.disabled {
    opacity: 0.4;
  }
}
