.future {
  .section__inner {
    overflow-x: unset;
  }

  &__title {
    position: relative;
    grid-area: 1 / 2 / 2 / 5;
    margin-block-end: 50px;
  }

  &__pic-1 {
    position: relative;
    grid-area: 2 / 2 / 4 / 4;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__pic-2 {
    position: relative;
    grid-area: 1 / -3 / 3 / -1;
  }

  &__list {
    counter-reset: item;

    display: flex;
    grid-area: 3 / -3 / 4 / -1;
    flex-direction: column;
    gap: 15px;

    padding: 0;
  }

  &__item {
    --i-size: 33px;
    --ol-size: 25px;

    position: relative;

    padding-block: 5px;
    padding-inline: 50px 20px;

    font-size: clamp(18px, 1.5vw, 25px);
    line-height: 1.4;
    list-style: none;

    &::before {
      content: '';

      position: absolute;
      top: 5px;
      left: 0;

      width: var(--i-size);
      height: var(--i-size);

      background-color: $color-orange;
    }

    &::after {
      content: '0' counter(item);
      counter-increment: item;

      position: absolute;
      top: 5px;
      left: 3px;

      font-size: var(--ol-size);
      color: white;
    }

    &:has(.future__link) {
      margin-top: 10px;
      padding-inline: 0;
      font-size: clamp(14px, 1.5vw, 19px);

      &::before,
      &::after {
        content: none;
      }
    }
  }

  &__link {
    text-decoration: underline;
    @include hover {
      color: $color-orange;
    }
  }

  &__pic-3 {
    grid-area: 4 / 1 / 5 / -1;
    margin-block-start: 50px;
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / 3;
    }

    &__pic-1 {
      grid-area: 2 / 1 / 4 / 3;
      height: 100%;

      &.pic__decor::before {
        left: calc(var(--p-inline) * -1);
      }
    }

    &__pic-2 {
      grid-area: 1 / 3 / 3 / 5;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__list {
      grid-area: 3 / 3 / 4 / 5;
      padding-inline: 5%;
    }

    &__pic-3 {
      grid-area: 4 / 1 / 5 / -1;
      height: 90%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @include tzar-breakpoint-down(md) {
    &__item {
      font-size: 16px;
    }

    &__pic-3 {
      height: auto;
    }
  }
  @include tzar-breakpoint-down(sm) {
    .section__inner {
      display: flex;
      flex-direction: column;
    }

    &__title {
      order: 1;
      margin-block-end: 16px;
    }

    &__pic-1 {
      order: 4;

      &.pic__decor::before {
        width: 25%;
        height: 40%;
      }
    }

    &__pic-2 {
      order: 2;
    }

    &__list {
      gap: 12px;
      order: 3;
      padding: 0;
    }

    &__item {
      --i-size: 25px;
      --ol-size: 18px;

      padding-inline: 35px 20px;
      font-size: 16px;

      &:has(.future__link) {
        margin-top: 0;
        font-size: clamp(14px, 1.5vw, 19px);
      }
    }

    &__pic-3 {
      display: none;
    }
  }
}

.future__title span {
  &:nth-child(2) {
    translate: 60px -7px;
  }

  &:nth-child(3) {
    translate: 60px -7px;
    padding-right: 30px;
  }
  @include tzar-breakpoint-down(lg) {
    &:nth-child(2) {
      translate: 30px 2px;
    }

    &:nth-child(3) {
      translate: 30px 2px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &:nth-child(2) {
      translate: 20px 2px;
    }

    &:nth-child(3) {
      translate: 20px 2px;
    }
  }
}
