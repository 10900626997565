.tools {
  &__title {
    position: relative;
    grid-area: 1 / 2 / 2 / -1;
    margin-block-end: 60px;
    padding-inline-end: 40px;
  }

  &__armax {
    display: grid;
    grid-area: 2 / 2 / 3 / -1;
    grid-template-columns: repeat(5, 1fr);
  }

  &__assets {
    display: grid;
    grid-area: 3 / 2 / 4 / 6;
    grid-template-columns: repeat(4, 1fr);
  }

  .title--lg {
    font-size: clamp(50px, 6vw, 100px);
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / -1;
    }

    &__armax {
      grid-area: 2 / 1 / 3 / -1;
      grid-template-columns: repeat(var(--columns), 1fr);
    }

    &__assets {
      grid-area: 3 / 1 / 4 / -1;
      grid-template-columns: repeat(var(--columns), 1fr);
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__title {
      margin-block-end: 40px;
    }

    .title--lg {
      font-size: clamp(36px, 2vw, 50px);
    }

    .title--sm {
      font-size: clamp(22px, 2vw, 32px);
    }
  }
  @include tzar-breakpoint-down(xs) {
    &__title {
      margin-block-end: 25px;
    }
  }
}

.armax {
  $logo-h: 80px;

  &__stat {
    display: grid;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: repeat(2, 1fr);
  }

  &__stat-text {
    grid-area: 1 / 1 / 2 / 3;
  }

  &__stat-item {
    &:not(:has(img)) {
      padding-top: $logo-h;
    }
  }

  &__logo {
    height: $logo-h;

    img {
      width: 100%;
    }
  }

  &__value {
    margin-block-end: 10px;
    font-weight: 600;
  }

  &__pic {
    position: relative;
    grid-area: 1 / 3 / 2 / 5;
    height: 500px;
    margin-top: -110px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    padding-right: 5%;
    font-size: 20px;
    line-height: 1.3;
  }

  &__lead-wrap {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  @include tzar-breakpoint-down(xxl) {
    &__stat-text {
      margin-block-end: 40px;
    }

    p {
      font-size: 16px;
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__pic {
      height: 330px;
      margin-top: auto;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__stat {
      display: flex;
      grid-area: auto;
      flex-direction: column;
      gap: 10px;

      &-text {
        margin-block-end: 20px;

        p {
          font-size: 14px;
        }
      }

      &-item {
        padding-top: 0 !important;
      }
    }

    &__logo {
      height: auto;
      margin-bottom: 40px;
    }

    &__value {
      margin-bottom: 5px;
    }

    &__pic {
      grid-area: 1 / 2 / 2 / 4;
      height: 100%;
    }

    &__lead-wrap {
      grid-area: 1 / -2 / 2 / -1;
    }
  }
  @include tzar-breakpoint-down(md) {
    &__pic {
      margin-top: auto;
    }

    p {
      font-size: 14px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    --offset: 200px;

    &__stat {
      position: relative;
      padding-top: var(--offset);
    }

    &__stat-text {
      position: absolute;
      top: 0;
      width: 200%;
    }

    &__logo {
      position: absolute;
      top: 120px;
      left: 0;

      width: 100%;
      margin: 0;
    }

    &__pic {
      display: flex;
      grid-area: 2 / 1 / 3 / -1;
      justify-content: center;
      height: 300px;
    }

    &__lead-wrap {
      margin-left: calc(10px + (var(--box-size)));
      padding-top: var(--offset);

      .lead {
        padding-inline-start: 30px;

        &::before {
          top: 0;
          left: 0;
          transform: translateX(100%);
        }
      }
    }

    p {
      font-size: 14px;
    }
  }
  @media screen and (width <=499px) {
    --offset: 230px;

    &__stat {
      position: relative;
    }

    &__logo {
      top: 160px;
    }
  }
}

.assets {
  background-color: white;

  p {
    font-size: clamp(14px, 1.5vw, 22px);
  }

  &__title {
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;

    span.orange {
      display: block;
      color: $color-orange;
    }
  }

  &__text {
    line-height: 1.3;
  }

  > *:not(.assets__video) {
    padding-block: 50px;
    padding-inline: 5%;
  }

  &__item {
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) span {
        transition-delay: #{(($i - 1) * 0.2)}s;
      }
    }
  }

  // &__video {
  //   position: relative;

  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: end;

  //   margin-block-start: auto;

  //   &-img {
  //     flex-grow: 1;
  //     height: 70%;
  //   }
  // }

  &__link {
    cursor: pointer;

    display: flex;
    gap: 10px;

    width: 100%;
    margin: 0;
    padding-block: 15px 10px;
    padding-inline: 30px 20px;

    font-size: clamp(14px, 1.5vw, 20px);
    color: white;

    background-color: $color-orange;
    @include hover {
      color: white;
      text-decoration: underline;
    }
  }

  &__link-icon {
    --icon-size: 30px;

    flex-shrink: 0;

    width: var(--icon-size);
    height: var(--icon-size);

    background-image: url('../images/icons/tools.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @include tzar-breakpoint-down(xxl) {
    &__link {
      padding-inline: 15px 5px;
    }

    &__link-icon {
      --icon-size: 25px;
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__link-icon {
      --icon-size: 22px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    // position: relative;

    // &__video {
    //   position: absolute;
    //   bottom: 0;
    //   display: grid;
    //   grid-template-columns: repeat(var(--columns), 1fr);

    //   &-img {
    //     display: none;
    //   }
    // }

    &__link {
      grid-area: 1 / -2 / 2 / -1;
    }

    > *:not(.assets__video) {
      padding-block: 30px 70px;
    }
  }
  @include tzar-breakpoint-down(md) {
    > *:not(.assets__video) {
      padding-block: 20px 50px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    position: relative;
    grid-template-rows: 1fr 1fr;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: rgba(var(--color-gray), 0.5);
    }

    &::before {
      top: 0;
      left: 50%;
      translate: -50% 0;

      width: 1.5px;
      height: 100%;
    }

    &::after {
      top: 50%;
      left: 0;
      translate: 0 -50%;

      width: 100%;
      height: 1.5px;
    }

    &__link {
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }

    > *:not(.assets__video) {
      margin-bottom: 0;
    }
  }
}

.tools__title span {
  &:nth-child(2) {
    translate: 80px -7px;
  }

  &:nth-child(3) {
    translate: 80px -7px;
  }
  @include tzar-breakpoint-down(md) {
    &:nth-child(2) {
      translate: 40px -5px;
    }

    &:nth-child(3) {
      translate: 40px -5px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &:nth-child(2) {
      translate: 20px -3px;
    }

    &:nth-child(3) {
      translate: 20px -3px;
    }
  }
}
