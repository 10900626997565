:root {
  /** Цвета */
  --color-gray: 222, 222, 222; // #DEDEDE

  /** Layout */
  --p-inline: 100px;
  --header-height: 56px;
  --header-z: 10;
  --footer-height: 42px;
  --footer-z: 10;
  --page-gutters: 24px;
  --aside-width: 300px;
  --nav-width: 80px;
  --nav-z: 9;
  --columns: 6;
  @include tzar-breakpoint-down(xxl) {
    --p-inline: 70px;
  }
  @include tzar-breakpoint-down(xl) {
    --p-inline: 60px;
  }
  @include tzar-breakpoint-down(lg) {
    --columns: 4;
    --p-inline: 40px;
  }
  @include tzar-breakpoint-down(md) {
    --p-inline: 20px;
  }
  @include tzar-breakpoint-down(sm) {
    --columns: 2;
    --p-inline: 14px;
  }
}

$color-orange: #ed7817;
$color-black: black;
$color-black-1: #090204;
$color-text: #2d2f33;
$color-red: #e42313;
$color-yellow: #fab72d;
$color-gray-3: #969696;
$color-gray-2: #dedede;
$color-gray-1: #f5f5f5;
$base_font-size: 18px;
