p {
  position: relative;
  margin-block-start: 0;
  color: $color-text;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1 {
  position: relative;
  margin-block-start: 0;
}

.section {
  --section--pb: 90px;

  position: relative;
  height: auto;

  &--gray {
    position: relative;
    background-color: $color-gray-1;

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 0;

      width: var(--p-inline);
      height: 100%;

      background-color: $color-gray-1;
    }

    &::before {
      left: calc(var(--p-inline) * -1);
    }

    &::after {
      right: calc(var(--p-inline) * -1);
    }
  }

  &__inner {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-template-rows: auto;

    height: 100%;
    padding-block: var(--section--pb);
  }
  @include tzar-breakpoint-down(lg) {
    --section--pb: 65px;

    .title {
      margin-block-start: 60px;
    }
  }
}

.section__title {
  $colors: (
    'yellow': $color-yellow,
    'orange': $color-orange,
    'red': $color-red,
  );

  position: absolute;
  top: var(--section--pb);
  left: 0;

  grid-area: 1 / 1 / 2 / 2;

  height: fit-content;
  margin: 0;
  padding-block: 7px;
  padding-inline: 15px;

  font-weight: 400;
  color: white;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    translate: calc(var(--p-inline) * -1) 0;

    width: calc(var(--p-inline) + 155px);
    height: 100%;
  }

  span {
    position: relative;
  }
  @each $name, $color in $colors {
    &--#{$name}::before {
      background-color: $color;
    }
  }
  @include tzar-breakpoint-down(xl) {
    padding-inline: 10px;
  }
  @include tzar-breakpoint-down(lg) {
    width: auto;
    padding-inline-end: 30px;
    font-size: 18px;
  }
  @include tzar-breakpoint-down(md) {
    font-size: 16px;
  }
}

.grid {
  $border-width: 2px;

  position: absolute;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: 1fr;

  width: 100%;
  min-height: 100%;

  &__item {
    border-inline: solid rgba(var(--color-gray), 0.5);
    border-inline-width: calc($border-width / 2);

    &:first-child {
      border-inline-start-width: $border-width;
    }

    &:last-child {
      border-inline-end-width: $border-width;
    }
  }
}

.title {
  position: relative;
  margin-block: 0;
  font-weight: 400;
  line-height: 1.1;

  span {
    display: block;
    width: fit-content;
    text-transform: uppercase;
  }

  &--xl {
    font-size: clamp(62px, 7vw, 120px);
    @include tzar-breakpoint-down(sm) {
      font-size: 46px;
    }
    @include tzar-breakpoint-down(xs) {
      font-size: 36px;
    }
  }

  &--lg {
    font-size: clamp(28px, 4.5vw, 80px);
  }

  &--md {
    font-size: clamp(26px, 3.2vw, 50px);
  }

  &--sm {
    font-size: clamp(28px, 2.3vw, 40px);
  }

  &--xs {
    font-size: clamp(14px, 2vw, 22px);
    font-weight: 500;
  }

  &--orange {
    color: $color-orange;
  }

  &--black {
    color: $color-black;
  }
}

.lead {
  --box-size: 24px;

  position: relative;
  padding-right: 5%;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: -16px;
    translate: -100% 0;

    width: var(--box-size);
    height: var(--box-size);

    background-color: $color-orange;
  }
  @include tzar-breakpoint-down(xl) {
    --box-size: 22px;
  }
  @include tzar-breakpoint-down(md) {
    --box-size: 18px;
  }
  @include tzar-breakpoint-down(sm) {
    --box-size: 16px;

    &::before {
      left: -10px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.link {
  position: relative;
  width: fit-content;
  padding-inline-end: 22px;

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: 0;
    translate: 0 -50%;

    width: 16px;
    height: 16px;

    background-image: url('../images/icons/link.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @include hover {
    color: $color-orange;
  }
}

.pic {
  &__decor {
    position: absolute;
    bottom: -33px;
    left: -37px;

    width: 35%;
    height: 50%;

    &--dark {
      background-color: rgba($color-orange, 0.7);
    }

    &--light {
      background-color: rgb(250 183 45 / 80%);
    }
    @include tzar-breakpoint-down(lg) {
      left: calc(var(--p-inline) * -1);
    }
  }
}

.pic-full {
  position: relative;
}

.decor {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: 1fr;

  width: 100%;
  height: 100%;

  transition: 5s ease;

  &::before,
  &::after {
    content: '';

    position: absolute;
    top: 0;

    width: var(--p-inline);
    height: 100%;

    background-color: #fff;

    transition: all 0.5s ease-in-out;
  }

  &::before {
    left: calc(var(--p-inline) * -1);
  }

  &::after {
    right: calc(var(--p-inline) * -1);
  }

  &__item {
    $border-width: 2px;

    position: relative;

    overflow: hidden;

    opacity: 1;
    border-inline: solid rgba(var(--color-gray));
    border-inline-width: calc($border-width / 2);

    transition: opacity 0.5s ease-in-out;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: white;

      transition: all 0.5s ease-in-out;
    }

    &:first-child {
      border-inline-start-width: $border-width;
    }

    &:last-child {
      border-inline-end-width: $border-width;
    }
    @for $i from 1 through 6 {
      transition-delay: #{($i * 0.2)}s;

      &:nth-child(#{$i})::before {
        transition-delay: #{($i * 0.2)}s;
      }
    }
  }

  &.decor--reverse {
    .decor__item {
      @for $i from 1 through 6 {
        transition-delay: #{((7 - $i) * 0.2)}s;

        &:nth-child(#{$i})::before {
          transition-delay: #{((7 - $i) * 0.2)}s;
        }
      }
    }
  }
}
