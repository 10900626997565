html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

a {
  --link-color: $ color-text;
  --link-color_visited: $ color-text;
  --link-color_hover: $ color-text;
  --link-color_disabled: $ color-text;
  color: var(--link-color);
  text-decoration: none;
}

a:visited {
  color: var(--link-color_visited);
}

a:hover, a:active {
  color: var(--link-color_hover);
}

a:disabled, a.disabled {
  pointer-events: none;
  color: var(--link-color_disabled);
}

.external-link {
  --external-link-color: var(--var_3);
  --external-link-color_visited: var(--var_5);
  --external-link-color_hover: var(--var_1);
  --external-link-color_disabled: var(--var_4);
  cursor: alias;
  color: var(--external-link-color);
  text-decoration: underline;
}

.external-link:visited {
  color: var(--external-link-color_visited);
}

.external-link:hover, .external-link:active {
  color: var(--external-link-color_hover);
  text-decoration: none;
}

.external-link:disabled, .external-link.disabled {
  pointer-events: none;
  color: var(--external-link-color_visited);
}

button {
  --btn-color: var(--var_6);
  --btn-bg: var(--var_1);
  --btn-border: var(--var_6);
  --btn-color_hover: var(--var_7);
  --btn-bg_hover: var(--var_8);
  --btn-border_hover: var(--var_7);
  --btn-color_disabled: var(--var_1);
  --btn-bg_disabled: var(--var_5);
  --btn-border_disabled: var(--var_1);
  cursor: pointer;
  color: var(--btn-color);
  background-color: var(--btn-bg);
  border: 2px solid var(--btn-border);
}

button:hover, button:active {
  color: var(--btn-color_hover);
  background-color: var(--btn-bg_hover);
  border-color: var(--btn-border_hover);
}

button:disabled, button.disabled {
  pointer-events: none;
  background-color: var(--btn-bg_disabled);
}

.button-light {
  --btn-color: #5fffff;
  --btn-bg: #979393;
  --btn-border: #000;
  --btn-color_hover: #979393;
  --btn-bg_hover: #5fffff;
  --btn-color_disabled: #5fffff;
  --btn-bg_disabled: #979393;
  --btn-border_disabled: #000;
}

.button-light:disabled, .button-light.disabled {
  opacity: .4;
}

.button-dark {
  --btn-color: #fff;
  --btn-bg: #000;
  --btn-border: #fff;
  --btn-color_hover: #000;
  --btn-bg_hover: #fff;
  --btn-border_hover: #000;
  --btn-color_disabled: #fff;
  --btn-bg_disabled: #000;
  --btn-border_disabled: #fff;
}

.button-dark:disabled, .button-dark.disabled {
  opacity: .4;
}

:root {
  --tzar-breakpoint-mobile: 0;
  --tzar-breakpoint-xs: 499px;
  --tzar-breakpoint-sm: 699px;
  --tzar-breakpoint-md: 889px;
  --tzar-breakpoint-lg: 1191px;
  --tzar-breakpoint-xl: 1401px;
  --tzar-breakpoint-xxl: 1681px;
  --tzar-breakpoint-ultra: 1921px;
  --tzar-gutter: 24px;
  --grid-breakpoint-mobile: 0;
  --grid-breakpoint-xs: 399px;
  --grid-breakpoint-sm: 699px;
  --grid-breakpoint-md: 889px;
  --grid-breakpoint-lg: 1191px;
  --grid-breakpoint-xl: 1401px;
  --grid-breakpoint-xxl: 1681px;
  --grid-breakpoint-ultra: 1921px;
  --grid-gutter: 24px;
}

.tzar-row {
  box-sizing: border-box;
  margin-right: calc(-.5 * var(--tzar-gutter, 24px));
  margin-left: calc(-.5 * var(--tzar-gutter, 24px));
  flex-flow: wrap;
  flex: 0 auto;
  display: flex;
}

.tzar-row > * {
  padding-right: calc(.5 * var(--tzar-gutter, 24px));
  padding-left: calc(.5 * var(--tzar-gutter, 24px));
  flex: 0 auto;
}

.tzar-col {
  flex: 1 0;
}

.tzar-col-1 {
  flex: none;
  width: 8.33333%;
}

.tzar-col-2 {
  flex: none;
  width: 16.6667%;
}

.tzar-col-3 {
  flex: none;
  width: 25%;
}

.tzar-col-4 {
  flex: none;
  width: 33.3333%;
}

.tzar-col-5 {
  flex: none;
  width: 41.6667%;
}

.tzar-col-6 {
  flex: none;
  width: 50%;
}

.tzar-col-7 {
  flex: none;
  width: 58.3333%;
}

.tzar-col-8 {
  flex: none;
  width: 66.6667%;
}

.tzar-col-9 {
  flex: none;
  width: 75%;
}

.tzar-col-10 {
  flex: none;
  width: 83.3333%;
}

.tzar-col-11 {
  flex: none;
  width: 91.6667%;
}

.tzar-col-12 {
  flex: none;
  width: 100%;
}

.tzar-offset-1 {
  margin-left: 8.33333%;
}

.tzar-offset-2 {
  margin-left: 16.6667%;
}

.tzar-offset-3 {
  margin-left: 25%;
}

.tzar-offset-4 {
  margin-left: 33.3333%;
}

.tzar-offset-5 {
  margin-left: 41.6667%;
}

.tzar-offset-6 {
  margin-left: 50%;
}

.tzar-offset-7 {
  margin-left: 58.3333%;
}

.tzar-offset-8 {
  margin-left: 66.6667%;
}

.tzar-offset-9 {
  margin-left: 75%;
}

.tzar-offset-10 {
  margin-left: 83.3333%;
}

.tzar-offset-11 {
  margin-left: 91.6667%;
}

.no-gutter {
  --tzar-gutter: 0;
}

@media (width <= 498.98px) {
  .tzar-col-xs-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-xs-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-xs-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-xs-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-xs-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-xs-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-xs-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-xs-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-xs-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-xs-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-xs-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-xs-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-xs-0 {
    margin-left: 0;
  }

  .tzar-offset-xs-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-xs-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-xs-3 {
    margin-left: 25%;
  }

  .tzar-offset-xs-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-xs-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-xs-6 {
    margin-left: 50%;
  }

  .tzar-offset-xs-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-xs-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-xs-9 {
    margin-left: 75%;
  }

  .tzar-offset-xs-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-xs-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-xs {
    --tzar-gutter: 0;
  }
}

@media (width <= 698.98px) {
  .tzar-col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-sm-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-sm-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-sm-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-sm-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-sm-0 {
    margin-left: 0;
  }

  .tzar-offset-sm-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-sm-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-sm-3 {
    margin-left: 25%;
  }

  .tzar-offset-sm-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-sm-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-sm-6 {
    margin-left: 50%;
  }

  .tzar-offset-sm-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-sm-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-sm-9 {
    margin-left: 75%;
  }

  .tzar-offset-sm-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-sm-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-sm {
    --tzar-gutter: 0;
  }
}

@media (width <= 888.98px) {
  .tzar-col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-md-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-md-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-md-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-md-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-md-0 {
    margin-left: 0;
  }

  .tzar-offset-md-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-md-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-md-3 {
    margin-left: 25%;
  }

  .tzar-offset-md-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-md-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-md-6 {
    margin-left: 50%;
  }

  .tzar-offset-md-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-md-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-md-9 {
    margin-left: 75%;
  }

  .tzar-offset-md-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-md-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-md {
    --tzar-gutter: 0;
  }
}

@media (width <= 1190.98px) {
  .tzar-col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-lg-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-lg-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-lg-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-lg-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-lg-0 {
    margin-left: 0;
  }

  .tzar-offset-lg-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-lg-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-lg-3 {
    margin-left: 25%;
  }

  .tzar-offset-lg-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-lg-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-lg-6 {
    margin-left: 50%;
  }

  .tzar-offset-lg-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-lg-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-lg-9 {
    margin-left: 75%;
  }

  .tzar-offset-lg-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-lg-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-lg {
    --tzar-gutter: 0;
  }
}

@media (width <= 1400.98px) {
  .tzar-col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-xl-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-xl-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-xl-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-xl-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-xl-0 {
    margin-left: 0;
  }

  .tzar-offset-xl-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-xl-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-xl-3 {
    margin-left: 25%;
  }

  .tzar-offset-xl-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-xl-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-xl-6 {
    margin-left: 50%;
  }

  .tzar-offset-xl-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-xl-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-xl-9 {
    margin-left: 75%;
  }

  .tzar-offset-xl-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-xl-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-xl {
    --tzar-gutter: 0;
  }
}

@media (width <= 1680.98px) {
  .tzar-col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-xxl-0 {
    margin-left: 0;
  }

  .tzar-offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-xxl-3 {
    margin-left: 25%;
  }

  .tzar-offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-xxl-6 {
    margin-left: 50%;
  }

  .tzar-offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-xxl-9 {
    margin-left: 75%;
  }

  .tzar-offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-xxl {
    --tzar-gutter: 0;
  }
}

@media (width >= 1920.98px) {
  .tzar-col-ultra-1 {
    flex: none;
    width: 8.33333%;
  }

  .tzar-col-ultra-2 {
    flex: none;
    width: 16.6667%;
  }

  .tzar-col-ultra-3 {
    flex: none;
    width: 25%;
  }

  .tzar-col-ultra-4 {
    flex: none;
    width: 33.3333%;
  }

  .tzar-col-ultra-5 {
    flex: none;
    width: 41.6667%;
  }

  .tzar-col-ultra-6 {
    flex: none;
    width: 50%;
  }

  .tzar-col-ultra-7 {
    flex: none;
    width: 58.3333%;
  }

  .tzar-col-ultra-8 {
    flex: none;
    width: 66.6667%;
  }

  .tzar-col-ultra-9 {
    flex: none;
    width: 75%;
  }

  .tzar-col-ultra-10 {
    flex: none;
    width: 83.3333%;
  }

  .tzar-col-ultra-11 {
    flex: none;
    width: 91.6667%;
  }

  .tzar-col-ultra-12 {
    flex: none;
    width: 100%;
  }

  .tzar-offset-ultra-0 {
    margin-left: 0;
  }

  .tzar-offset-ultra-1 {
    margin-left: 8.33333%;
  }

  .tzar-offset-ultra-2 {
    margin-left: 16.6667%;
  }

  .tzar-offset-ultra-3 {
    margin-left: 25%;
  }

  .tzar-offset-ultra-4 {
    margin-left: 33.3333%;
  }

  .tzar-offset-ultra-5 {
    margin-left: 41.6667%;
  }

  .tzar-offset-ultra-6 {
    margin-left: 50%;
  }

  .tzar-offset-ultra-7 {
    margin-left: 58.3333%;
  }

  .tzar-offset-ultra-8 {
    margin-left: 66.6667%;
  }

  .tzar-offset-ultra-9 {
    margin-left: 75%;
  }

  .tzar-offset-ultra-10 {
    margin-left: 83.3333%;
  }

  .tzar-offset-ultra-11 {
    margin-left: 91.6667%;
  }

  .no-gutter-ultra {
    --tzar-gutter: 0;
  }
}

:root {
  --color-gray: 222, 222, 222;
  --p-inline: 100px;
  --header-height: 56px;
  --header-z: 10;
  --footer-height: 42px;
  --footer-z: 10;
  --page-gutters: 24px;
  --aside-width: 300px;
  --nav-width: 80px;
  --nav-z: 9;
  --columns: 6;
}

@media (width <= 1680.98px) {
  :root {
    --p-inline: 70px;
  }
}

@media (width <= 1400.98px) {
  :root {
    --p-inline: 60px;
  }
}

@media (width <= 1190.98px) {
  :root {
    --columns: 4;
    --p-inline: 40px;
  }
}

@media (width <= 888.98px) {
  :root {
    --p-inline: 20px;
  }
}

@media (width <= 698.98px) {
  :root {
    --columns: 2;
    --p-inline: 14px;
  }
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-100.f3d032e9.woff2") format("woff2"), url("GTEestiProText-100.7461b81d.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-200.32c465e7.woff2") format("woff2"), url("GTEestiProText-200.a80db9a0.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-300.ab6de8e7.woff2") format("woff2"), url("GTEestiProText-300.d5aac7ff.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-400.528784db.woff2") format("woff2"), url("GTEestiProText-400.bd56ec30.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-400.528784db.woff2") format("woff2"), url("GTEestiProText-400.bd56ec30.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-700.24c86d8d.woff2") format("woff2"), url("GTEestiProText-700.d1a7e790.woff") format("woff");
}

@font-face {
  font-family: GTEestiProText;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("GTEestiProText-900.f0bc7ac1.woff2") format("woff2"), url("GTEestiProText-900.06bfae43.woff") format("woff");
}

html {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  padding: 0;
  padding-inline: var(--p-inline);
  color: #000;
  background-color: #fff;
  min-height: 100vh;
  margin: 0;
  font-family: GTEestiProText, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.visually-hidden {
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

p {
  color: #2d2f33;
  margin-block-start: 0;
  position: relative;
}

ul, li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1 {
  margin-block-start: 0;
  position: relative;
}

.section {
  --section--pb: 90px;
  height: auto;
  position: relative;
}

.section--gray {
  background-color: #f5f5f5;
  position: relative;
}

.section--gray:before, .section--gray:after {
  content: "";
  width: var(--p-inline);
  background-color: #f5f5f5;
  height: 100%;
  position: absolute;
  top: 0;
}

.section--gray:before {
  left: calc(var(--p-inline) * -1);
}

.section--gray:after {
  right: calc(var(--p-inline) * -1);
}

.section__inner {
  grid-template-columns: repeat(var(--columns), 1fr);
  padding-block: var(--section--pb);
  grid-template-rows: auto;
  height: 100%;
  display: grid;
  overflow-x: hidden;
}

@media (width <= 1190.98px) {
  .section {
    --section--pb: 65px;
  }

  .section .title {
    margin-block-start: 60px;
  }
}

.section__title {
  top: var(--section--pb);
  color: #fff;
  grid-area: 1 / 1 / 2 / 2;
  height: fit-content;
  margin: 0;
  padding-block: 7px;
  padding-inline: 15px;
  font-weight: 400;
  position: absolute;
  left: 0;
}

.section__title:before {
  content: "";
  translate: calc(var(--p-inline) * -1) 0;
  width: calc(var(--p-inline)  + 155px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.section__title span {
  position: relative;
}

.section__title--yellow:before {
  background-color: #fab72d;
}

.section__title--orange:before {
  background-color: #ed7817;
}

.section__title--red:before {
  background-color: #e42313;
}

@media (width <= 1400.98px) {
  .section__title {
    padding-inline: 10px;
  }
}

@media (width <= 1190.98px) {
  .section__title {
    width: auto;
    padding-inline-end: 30px;
    font-size: 18px;
  }
}

@media (width <= 888.98px) {
  .section__title {
    font-size: 16px;
  }
}

.grid {
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  min-height: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.grid__item {
  border-inline: solid rgba(var(--color-gray), .5);
  border-inline-width: 1px;
}

.grid__item:first-child {
  border-inline-start-width: 2px;
}

.grid__item:last-child {
  border-inline-end-width: 2px;
}

.title {
  margin-block: 0;
  font-weight: 400;
  line-height: 1.1;
  position: relative;
}

.title span {
  text-transform: uppercase;
  width: fit-content;
  display: block;
}

.title--xl {
  font-size: clamp(62px, 7vw, 120px);
}

@media (width <= 698.98px) {
  .title--xl {
    font-size: 46px;
  }
}

@media (width <= 398.98px) {
  .title--xl {
    font-size: 36px;
  }
}

.title--lg {
  font-size: clamp(28px, 4.5vw, 80px);
}

.title--md {
  font-size: clamp(26px, 3.2vw, 50px);
}

.title--sm {
  font-size: clamp(28px, 2.3vw, 40px);
}

.title--xs {
  font-size: clamp(14px, 2vw, 22px);
  font-weight: 500;
}

.title--orange {
  color: #ed7817;
}

.title--black {
  color: #000;
}

.lead {
  --box-size: 24px;
  padding-right: 5%;
  position: relative;
}

.lead:before {
  content: "";
  width: var(--box-size);
  height: var(--box-size);
  background-color: #ed7817;
  position: absolute;
  top: 0;
  left: -16px;
  translate: -100%;
}

@media (width <= 1400.98px) {
  .lead {
    --box-size: 22px;
  }
}

@media (width <= 888.98px) {
  .lead {
    --box-size: 18px;
  }
}

@media (width <= 698.98px) {
  .lead {
    --box-size: 16px;
  }

  .lead:before {
    left: -10px;
  }

  .lead p:last-child {
    margin-bottom: 0;
  }
}

.link {
  width: fit-content;
  padding-inline-end: 22px;
  position: relative;
}

.link:after {
  content: "";
  background-image: url("link.72dbc4d5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 0;
  translate: 0 -50%;
}

@media (hover: hover) {
  .link:hover {
    color: #ed7817;
  }
}

.pic__decor {
  width: 35%;
  height: 50%;
  position: absolute;
  bottom: -33px;
  left: -37px;
}

.pic__decor--dark {
  background-color: #ed7817b3;
}

.pic__decor--light {
  background-color: #fab72dcc;
}

@media (width <= 1190.98px) {
  .pic__decor {
    left: calc(var(--p-inline) * -1);
  }
}

.pic-full {
  position: relative;
}

.decor {
  z-index: 2;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  transition: all 5s;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.decor:before, .decor:after {
  content: "";
  width: var(--p-inline);
  background-color: #fff;
  height: 100%;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
}

.decor:before {
  left: calc(var(--p-inline) * -1);
}

.decor:after {
  right: calc(var(--p-inline) * -1);
}

.decor__item {
  opacity: 1;
  border-inline: solid rgba(var(--color-gray));
  border-inline-width: 1px;
  transition: opacity .5s ease-in-out 1.2s;
  position: relative;
  overflow: hidden;
}

.decor__item:before {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.decor__item:first-child {
  border-inline-start-width: 2px;
}

.decor__item:last-child {
  border-inline-end-width: 2px;
}

.decor__item:first-child:before {
  transition-delay: .2s;
}

.decor__item:nth-child(2):before {
  transition-delay: .4s;
}

.decor__item:nth-child(3):before {
  transition-delay: .6s;
}

.decor__item:nth-child(4):before {
  transition-delay: .8s;
}

.decor__item:nth-child(5):before {
  transition-delay: 1s;
}

.decor__item:nth-child(6):before {
  transition-delay: 1.2s;
}

.decor.decor--reverse .decor__item {
  transition-delay: .2s;
}

.decor.decor--reverse .decor__item:first-child:before {
  transition-delay: 1.2s;
}

.decor.decor--reverse .decor__item:nth-child(2):before {
  transition-delay: 1s;
}

.decor.decor--reverse .decor__item:nth-child(3):before {
  transition-delay: .8s;
}

.decor.decor--reverse .decor__item:nth-child(4):before {
  transition-delay: .6s;
}

.decor.decor--reverse .decor__item:nth-child(5):before {
  transition-delay: .4s;
}

.decor.decor--reverse .decor__item:nth-child(6):before {
  transition-delay: .2s;
}

.front {
  position: relative;
}

.front .section__inner {
  height: 100svh;
  padding-block: 0;
}

.front__pic {
  z-index: -1;
  top: 0;
  left: calc(var(--p-inline) * -1);
  width: calc(100% + var(--p-inline) * 2);
  height: 100%;
  display: flex;
  position: absolute;
}

.front__pic img {
  object-fit: cover;
  width: 100%;
}

.front__text {
  z-index: 1;
  grid-area: 2 / 1 / 3 / 5;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  margin-top: auto;
  padding-block-end: 0;
  display: grid;
  position: relative;
}

.front__subtitle {
  color: #fff;
  white-space: nowrap;
  grid-area: 1 / 1 / 2 / 3;
  margin: auto 0 55px;
  font-size: clamp(38px, 3vw, 60px);
  line-height: 1;
  position: relative;
}

.front__subtitle span.orange {
  background-color: #ed7817;
  padding-block: 3px;
  display: inline-block;
}

.front__title {
  grid-area: 2 / 1 / 3 / -1;
  align-items: end;
  margin: 0;
  display: flex;
  position: relative;
}

.front__title img {
  width: 100%;
}

@media (width <= 1190.98px) {
  .front__text {
    grid-area: 1 / 1 / 3 / 4;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
  }

  .front__title {
    margin-block: auto 0;
  }

  .front__subtitle {
    margin-block: 160px auto;
  }
}

@media (width <= 698.98px) {
  .front {
    flex-direction: column;
    display: flex;
  }

  .front__text {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 100px;
    display: flex;
  }

  .front__subtitle, .front__title {
    margin: 0;
  }
}

.header {
  z-index: 3;
  top: 25px;
  left: var(--p-inline);
  width: calc(100% - var(--p-inline) * 2);
  background-color: #fff;
  border-radius: 80px;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding-block: 10px;
  padding-inline: 30px;
  display: flex;
  position: fixed;
  box-shadow: 0 2px 5px #0000001a;
}

.header__logos {
  align-items: center;
  gap: 32px;
  height: 100%;
  display: flex;
  position: relative;
}

.header__logos:before {
  content: "";
  background-color: #090204;
  width: 1.5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50%;
}

.header__armax, .header__evraz {
  align-items: center;
  width: 127px;
  height: 100%;
  display: flex;
}

.header__list {
  gap: 70px;
  display: flex;
}

.header__link {
  position: relative;
}

.header__link:after {
  content: "";
  background-color: currentColor;
  width: 0;
  height: 1.5px;
  transition: all .2s;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (hover: hover) {
  .header__link:hover:after {
    width: 100%;
    left: 0;
  }
}

.header img {
  object-fit: cover;
  width: 100%;
}

.header__burger {
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  width: 32px;
  height: 15px;
  padding-inline: 6px;
}

.header__burger-line {
  background-color: #2d2f33;
  width: 20px;
  height: 2px;
  transition: all .2s ease-in-out;
  display: block;
}

@media (width >= 699px) {
  .header__burger {
    display: none;
  }
}

@media (width <= 1680.98px) {
  .header__list {
    gap: 60px;
  }
}

@media (width <= 1400.98px) {
  .header {
    height: 54px;
  }

  .header__list {
    gap: 50px;
  }
}

@media (width <= 1190.98px) {
  .header {
    height: 50px;
    padding-block: 5px;
    padding-inline: 24px;
  }

  .header__list, .header__logos {
    gap: 26px;
  }

  .header__armax, .header__evraz {
    width: 106px;
  }
}

@media (width <= 888.98px) {
  .header {
    height: 40px;
    padding-inline: 12px;
    top: 15px;
  }

  .header__logos {
    gap: 20px;
  }

  .header__armax, .header__evraz {
    width: 96px;
  }

  .header__burger {
    display: flex;
  }

  .header__nav {
    translate: calc(100% + var(--p-inline));
    background-color: #fff;
    border-radius: 20px;
    width: auto;
    transition: all .2s ease-in-out;
    position: absolute;
    top: 60px;
    right: 0;
    box-shadow: 0 2px 5px #0000001a;
  }

  .header__list {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    padding: 10px 20px;
  }

  .header__link {
    font-size: 16px;
  }

  .menu-open .header__nav {
    translate: 0;
  }

  .menu-open .header__burger-line:first-child {
    translate: 0 6px;
    rotate: 45deg;
  }

  .menu-open .header__burger-line:nth-child(2) {
    transform: scaleX(0);
  }

  .menu-open .header__burger-line:nth-child(3) {
    translate: 0 -5px;
    rotate: -45deg;
  }
}

.tools__title {
  grid-area: 1 / 2 / 2 / -1;
  margin-block-end: 60px;
  padding-inline-end: 40px;
  position: relative;
}

.tools__armax {
  grid-area: 2 / 2 / 3 / -1;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.tools__assets {
  grid-area: 3 / 2 / 4 / 6;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.tools .title--lg {
  font-size: clamp(50px, 6vw, 100px);
}

@media (width <= 1190.98px) {
  .tools__title {
    grid-area: 1 / 1 / 2 / -1;
  }

  .tools__armax {
    grid-area: 2 / 1 / 3 / -1;
    grid-template-columns: repeat(var(--columns), 1fr);
  }

  .tools__assets {
    grid-area: 3 / 1 / 4 / -1;
    grid-template-columns: repeat(var(--columns), 1fr);
  }
}

@media (width <= 698.98px) {
  .tools__title {
    margin-block-end: 40px;
  }

  .tools .title--lg {
    font-size: clamp(36px, 2vw, 50px);
  }

  .tools .title--sm {
    font-size: clamp(22px, 2vw, 32px);
  }
}

@media (width <= 398.98px) {
  .tools__title {
    margin-block-end: 25px;
  }
}

.armax__stat {
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.armax__stat-text {
  grid-area: 1 / 1 / 2 / 3;
}

.armax__stat-item:not(:has(img)) {
  padding-top: 80px;
}

.armax__logo {
  height: 80px;
}

.armax__logo img {
  width: 100%;
}

.armax__value {
  margin-block-end: 10px;
  font-weight: 600;
}

.armax__pic {
  grid-area: 1 / 3 / 2 / 5;
  height: 500px;
  margin-top: -110px;
  position: relative;
}

.armax__pic img {
  object-fit: cover;
  height: 100%;
}

.armax__text {
  padding-right: 5%;
  font-size: 20px;
  line-height: 1.3;
}

.armax__lead-wrap {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

@media (width <= 1680.98px) {
  .armax__stat-text {
    margin-block-end: 40px;
  }

  .armax p {
    font-size: 16px;
  }
}

@media (width <= 1400.98px) {
  .armax__pic {
    height: 330px;
    margin-top: auto;
  }
}

@media (width <= 1190.98px) {
  .armax__stat {
    flex-direction: column;
    grid-area: auto;
    gap: 10px;
    display: flex;
  }

  .armax__stat-text {
    margin-block-end: 20px;
  }

  .armax__stat-text p {
    font-size: 14px;
  }

  .armax__stat-item {
    padding-top: 0 !important;
  }

  .armax__logo {
    height: auto;
    margin-bottom: 40px;
  }

  .armax__value {
    margin-bottom: 5px;
  }

  .armax__pic {
    grid-area: 1 / 2 / 2 / 4;
    height: 100%;
  }

  .armax__lead-wrap {
    grid-area: 1 / -2 / 2 / -1;
  }
}

@media (width <= 888.98px) {
  .armax__pic {
    margin-top: auto;
  }

  .armax p {
    font-size: 14px;
  }
}

@media (width <= 698.98px) {
  .armax {
    --offset: 200px;
  }

  .armax__stat {
    padding-top: var(--offset);
    position: relative;
  }

  .armax__stat-text {
    width: 200%;
    position: absolute;
    top: 0;
  }

  .armax__logo {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 120px;
    left: 0;
  }

  .armax__pic {
    grid-area: 2 / 1 / 3 / -1;
    justify-content: center;
    height: 300px;
    display: flex;
  }

  .armax__lead-wrap {
    margin-left: calc(10px + (var(--box-size)));
    padding-top: var(--offset);
  }

  .armax__lead-wrap .lead {
    padding-inline-start: 30px;
  }

  .armax__lead-wrap .lead:before {
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .armax p {
    font-size: 14px;
  }
}

@media screen and (width <= 499px) {
  .armax {
    --offset: 230px;
  }

  .armax__stat {
    position: relative;
  }

  .armax__logo {
    top: 160px;
  }
}

.assets {
  background-color: #fff;
}

.assets p {
  font-size: clamp(14px, 1.5vw, 22px);
}

.assets__title {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2;
}

.assets__title span.orange {
  color: #ed7817;
  display: block;
}

.assets__text {
  line-height: 1.3;
}

.assets > :not(.assets__video) {
  padding-block: 50px;
  padding-inline: 5%;
}

.assets__item:first-child span {
  transition-delay: 0s;
}

.assets__item:nth-child(2) span {
  transition-delay: .2s;
}

.assets__item:nth-child(3) span {
  transition-delay: .4s;
}

.assets__item:nth-child(4) span {
  transition-delay: .6s;
}

.assets__link {
  cursor: pointer;
  color: #fff;
  background-color: #ed7817;
  gap: 10px;
  width: 100%;
  margin: 0;
  padding-block: 15px 10px;
  padding-inline: 30px 20px;
  font-size: clamp(14px, 1.5vw, 20px);
  display: flex;
}

@media (hover: hover) {
  .assets__link:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.assets__link-icon {
  --icon-size: 30px;
  width: var(--icon-size);
  height: var(--icon-size);
  background-image: url("tools.f9228d1d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}

@media (width <= 1680.98px) {
  .assets__link {
    padding-inline: 15px 5px;
  }

  .assets__link-icon {
    --icon-size: 25px;
  }
}

@media (width <= 1400.98px) {
  .assets__link-icon {
    --icon-size: 22px;
  }
}

@media (width <= 1190.98px) {
  .assets__link {
    grid-area: 1 / -2 / 2 / -1;
  }

  .assets > :not(.assets__video) {
    padding-block: 30px 70px;
  }
}

@media (width <= 888.98px) {
  .assets > :not(.assets__video) {
    padding-block: 20px 50px;
  }
}

@media (width <= 698.98px) {
  .assets {
    grid-template-rows: 1fr 1fr;
    position: relative;
  }

  .assets:before, .assets:after {
    content: "";
    background-color: rgba(var(--color-gray), .5);
    position: absolute;
  }

  .assets:before {
    width: 1.5px;
    height: 100%;
    top: 0;
    left: 50%;
    translate: -50%;
  }

  .assets:after {
    width: 100%;
    height: 1.5px;
    top: 50%;
    left: 0;
    translate: 0 -50%;
  }

  .assets__link {
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .assets > :not(.assets__video) {
    margin-bottom: 0;
  }
}

.tools__title span:nth-child(2), .tools__title span:nth-child(3) {
  translate: 80px -7px;
}

@media (width <= 888.98px) {
  .tools__title span:nth-child(2), .tools__title span:nth-child(3) {
    translate: 40px -5px;
  }
}

@media (width <= 698.98px) {
  .tools__title span:nth-child(2), .tools__title span:nth-child(3) {
    translate: 20px -3px;
  }
}

.trust__header {
  grid-area: 1 / 2 / 2 / -1;
  margin-block-end: 15px;
  position: relative;
}

.trust__pic {
  grid-area: 5 / 1 / 5 / -1;
  display: block;
}

.trust__pic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.trust__list {
  display: grid;
}

.trust__list:first-of-type {
  grid-area: 2 / 2 / 3 / -2;
  grid-template-columns: repeat(4, 1fr);
  margin-block-end: 10px;
}

.trust__list:nth-of-type(2) {
  grid-area: 3 / 2 / 4 / 5;
  grid-template-columns: repeat(3, 1fr);
  margin-block-end: 60px;
}

.trust__item {
  --i-size: 57px;
  border-inline: 1px solid rgba(var(--color-gray), .5);
  background-color: #fff;
  padding-block: 30px;
  padding-inline: 20px;
  font-size: clamp(16px, 1.6vw, 22px);
  position: relative;
}

.trust__item-inner {
  position: relative;
}

.trust__item-logo {
  height: 35px;
  margin-block-end: 25px;
}

.trust__item-logo img {
  object-fit: contain;
  height: 100%;
}

.trust__item-value {
  margin-block-end: 5px;
  font-size: clamp(16px, 1.6vw, 22px);
  font-weight: 600;
}

.trust__item-text {
  margin-block-end: 0;
  font-size: clamp(14px, 1.6vw, 20px);
}

.trust__item-icon {
  margin-block-end: 30px;
}

@media (width <= 1400.98px) {
  .trust__pic {
    height: 450px;
  }

  .trust__item {
    padding-block: 20px;
    padding-inline: 15px;
  }
}

@media (width <= 1190.98px) {
  .trust__header {
    grid-area: 1 / 1 / 2 / -1;
  }

  .trust__list:first-of-type {
    grid-area: 2 / 1 / 3 / -1;
    grid-template-columns: repeat(4, 1fr);
    margin-block-end: 10px;
  }

  .trust__list:nth-of-type(2) {
    grid-area: 3 / 1 / 4 / 4;
    grid-template-columns: repeat(3, 1fr);
    margin-block-end: 60px;
  }

  .trust__item-icon {
    height: 50px;
  }

  .trust__item-icon img {
    height: 100%;
  }
}

@media (width <= 888.98px) {
  .trust__pic {
    height: 360px;
  }
}

@media (width <= 698.98px) {
  .trust__list:first-of-type {
    grid-area: 2 / 1 / 6 / 2;
    grid-template-columns: repeat(1, 1fr);
    margin-block-end: 20px;
  }

  .trust__list:nth-of-type(2) {
    grid-area: 2 / 2 / 5 / 3;
    grid-template-columns: repeat(1, 1fr);
    margin-block-end: 20px;
  }

  .trust__item {
    padding-block: 15px;
    padding-inline: 10px;
  }

  .trust__item-logo {
    height: 25px;
    margin-block-end: 20px;
  }

  .trust__item-icon {
    margin-block-end: 20px;
  }

  .trust__item-text {
    width: 90%;
  }

  .trust__pic {
    grid-area: 6 / 1 / 7 / -1;
  }
}

.trust__title span:nth-child(2) {
  translate: 80px -7px;
}

.trust__title span:nth-child(3) {
  translate: 160px -14px;
}

@media (width <= 1190.98px) {
  .trust__title span:nth-child(2) {
    translate: 20px -5px;
  }

  .trust__title span:nth-child(3) {
    translate: 40px -10px;
  }
}

@media (width <= 698.98px) {
  .trust__title span:nth-child(2) {
    translate: 8px -5px;
  }

  .trust__title span:nth-child(3) {
    translate: 16px -10px;
  }
}

.force .section__inner {
  overflow-x: unset;
}

.force__title {
  grid-area: 1 / 2 / 2 / 5;
  margin-block-end: 120px;
}

.force__pic-1 {
  grid-area: 2 / 2 / 4 / 4;
  min-height: 430px;
  display: flex;
  position: relative;
}

.force__pic-1 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.force__pic-2 {
  grid-area: 1 / 5 / 3 / -1;
  display: flex;
  position: relative;
}

.force__lead {
  grid-area: 3 / 5 / 5 / -1;
  padding-inline-end: 10px;
  font-size: clamp(20px, 1.5vw, 25px);
  line-height: 1.4;
}

.force__list {
  grid-area: 5 / 1 / 6 / -1;
  grid-template-areas: ". a b c d e";
  grid-template-columns: repeat(var(--columns), 1fr);
  border-top: 2px solid #dedede;
  padding-block: 50px 0;
  display: grid;
}

.force__item {
  padding-block: 30px;
  padding-inline: 20px;
  font-size: clamp(14px, 1.3vw, 18px);
  position: relative;
}

.force__item-inner {
  position: relative;
}

.force__item-icon {
  margin-block-end: 30px;
}

.force__item:first-child {
  grid-area: a;
}

.force__item:nth-child(2) {
  grid-area: b;
}

.force__item:nth-child(3) {
  grid-area: c;
}

.force__item:nth-child(4) {
  grid-area: d;
}

.force__item:nth-child(5) {
  grid-area: e;
}

.force__item:first-child .force__item-inner {
  transition-delay: .2s;
}

.force__item:nth-child(2) .force__item-inner {
  transition-delay: .4s;
}

.force__item:nth-child(3) .force__item-inner {
  transition-delay: .6s;
}

.force__item:nth-child(4) .force__item-inner {
  transition-delay: .8s;
}

.force__item:nth-child(5) .force__item-inner {
  transition-delay: 1s;
}

.force__release {
  grid-area: 4 / 5 / 5 / -1;
  align-items: center;
  display: flex;
}

.force__release-pic {
  background-color: #fab72d;
  flex-shrink: 0;
  width: 50%;
}

.force__release-link {
  text-wrap: balance;
  width: 40%;
  padding-inline: 10px;
}

@media (width <= 1190.98px) {
  .force__title {
    grid-area: 1 / 1 / 2 / 3;
    margin-block-end: 80px;
  }

  .force__pic-1 {
    min-height: unset;
    grid-area: 2 / 1 / 5 / 3;
    height: 100%;
  }

  .force__pic-1:before {
    left: calc(var(--p-inline) * -1);
  }

  .force__pic-2 {
    grid-area: 1 / 3 / 4 / 5;
    height: 90%;
  }

  .force__pic-2 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .force__release {
    grid-area: 4 / 3 / 5 / 5;
  }

  .force__release-link {
    width: 35%;
  }

  .force__list {
    grid-area: 6 / 1 / 7 / -1;
    grid-template-areas: ". a b c"
                         ". d e .";
  }

  .force__item {
    padding-block: 20px;
    padding-inline: 10px;
  }

  .force__item-icon {
    height: 45px;
    margin-block-end: 15px;
  }

  .force__item-icon img {
    object-fit: contain;
    height: 100%;
  }
}

@media (width <= 888.98px) {
  .force__title {
    margin-block-end: 60px;
  }
}

@media (width <= 698.98px) {
  .force .section__inner {
    flex-direction: column;
    padding-block: 70px;
    display: flex;
  }

  .force__title {
    margin-block-end: 16px;
  }

  .force__pic-1 {
    order: 10;
  }

  .force__pic-2 {
    margin-block-end: 20px;
  }

  .force__release-link {
    width: 45%;
  }

  .force__list {
    flex-flow: wrap;
    padding-block: 30px;
    display: flex;
  }

  .force__item {
    width: 50%;
    padding-block: 25px;
  }

  .force__item-icon {
    height: 40px;
  }

  .force .pic__wrap {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .force__title span:nth-child(2) {
    padding-right: 30px;
    translate: 30px;
  }
}

.force__title span:nth-child(2) {
  translate: 20px;
}

.future .section__inner {
  overflow-x: unset;
}

.future__title {
  grid-area: 1 / 2 / 2 / 5;
  margin-block-end: 50px;
  position: relative;
}

.future__pic-1 {
  grid-area: 2 / 2 / 4 / 4;
  position: relative;
}

.future__pic-1 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.future__pic-2 {
  grid-area: 1 / -3 / 3 / -1;
  position: relative;
}

.future__list {
  counter-reset: item;
  flex-direction: column;
  grid-area: 3 / -3 / 4 / -1;
  gap: 15px;
  padding: 0;
  display: flex;
}

.future__item {
  --i-size: 33px;
  --ol-size: 25px;
  padding-block: 5px;
  padding-inline: 50px 20px;
  font-size: clamp(18px, 1.5vw, 25px);
  line-height: 1.4;
  list-style: none;
  position: relative;
}

.future__item:before {
  content: "";
  width: var(--i-size);
  height: var(--i-size);
  background-color: #ed7817;
  position: absolute;
  top: 5px;
  left: 0;
}

.future__item:after {
  content: "0" counter(item);
  counter-increment: item;
  font-size: var(--ol-size);
  color: #fff;
  position: absolute;
  top: 5px;
  left: 3px;
}

.future__item:has(.future__link) {
  margin-top: 10px;
  padding-inline: 0;
  font-size: clamp(14px, 1.5vw, 19px);
}

.future__item:has(.future__link):before, .future__item:has(.future__link):after {
  content: none;
}

.future__link {
  text-decoration: underline;
}

@media (hover: hover) {
  .future__link:hover {
    color: #ed7817;
  }
}

.future__pic-3 {
  grid-area: 4 / 1 / 5 / -1;
  margin-block-start: 50px;
}

@media (width <= 1190.98px) {
  .future__title {
    grid-area: 1 / 1 / 2 / 3;
  }

  .future__pic-1 {
    grid-area: 2 / 1 / 4 / 3;
    height: 100%;
  }

  .future__pic-1.pic__decor:before {
    left: calc(var(--p-inline) * -1);
  }

  .future__pic-2 {
    grid-area: 1 / 3 / 3 / 5;
  }

  .future__pic-2 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .future__list {
    grid-area: 3 / 3 / 4 / 5;
    padding-inline: 5%;
  }

  .future__pic-3 {
    grid-area: 4 / 1 / 5 / -1;
    height: 90%;
  }

  .future__pic-3 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (width <= 888.98px) {
  .future__item {
    font-size: 16px;
  }

  .future__pic-3 {
    height: auto;
  }
}

@media (width <= 698.98px) {
  .future .section__inner {
    flex-direction: column;
    display: flex;
  }

  .future__title {
    order: 1;
    margin-block-end: 16px;
  }

  .future__pic-1 {
    order: 4;
  }

  .future__pic-1.pic__decor:before {
    width: 25%;
    height: 40%;
  }

  .future__pic-2 {
    order: 2;
  }

  .future__list {
    order: 3;
    gap: 12px;
    padding: 0;
  }

  .future__item {
    --i-size: 25px;
    --ol-size: 18px;
    padding-inline: 35px 20px;
    font-size: 16px;
  }

  .future__item:has(.future__link) {
    margin-top: 0;
    font-size: clamp(14px, 1.5vw, 19px);
  }

  .future__pic-3 {
    display: none;
  }
}

.future__title span:nth-child(2) {
  translate: 60px -7px;
}

.future__title span:nth-child(3) {
  padding-right: 30px;
  translate: 60px -7px;
}

@media (width <= 1190.98px) {
  .future__title span:nth-child(2), .future__title span:nth-child(3) {
    translate: 30px 2px;
  }
}

@media (width <= 698.98px) {
  .future__title span:nth-child(2), .future__title span:nth-child(3) {
    translate: 20px 2px;
  }
}

.more__title {
  grid-area: 1 / 2 / 2 / 5;
  margin-block-end: 80px;
}

.more__balmax {
  grid-area: 2 / 2 / 3 / 4;
}

.more__balmax .pic {
  position: relative;
}

.more__balmax .pic:before {
  content: "";
  background-color: #fab72d;
  width: 45%;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  translate: 0 -100%;
}

.more__balmax .pic, .more__balmax .more__info {
  overflow: hidden;
}

.more__balmax .pic > *, .more__balmax .more__info > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.more__balmax .pic.anim > *, .more__balmax .more__info.anim > * {
  opacity: 1;
  translate: 0;
}

.more__evraz {
  grid-area: 2 / -3 / 3 / -1;
}

.more__evraz .pic, .more__evraz .more__info {
  overflow: hidden;
}

.more__evraz .pic > *, .more__evraz .more__info > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 100%;
}

.more__evraz .pic.anim > *, .more__evraz .more__info.anim > * {
  opacity: 1;
  translate: 0;
}

.more__item {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.more__info {
  flex-direction: column;
  gap: 7px;
  display: flex;
  position: relative;
}

.more__link {
  font-size: clamp(16px, 2vw, 25px);
}

.more__logo {
  height: 32px;
}

.more__logo img {
  height: 100%;
}

.more .pic {
  position: relative;
}

@media (width <= 1190.98px) {
  .more__title {
    grid-area: 1 / 1 / 2 / 3;
  }

  .more__balmax {
    grid-area: 2 / 1 / 3 / 3;
    padding-inline-end: 50px;
  }

  .more__evraz {
    grid-area: 2 / 3 / 3 / 5;
    padding-block-start: 16px;
  }

  .more__logo {
    height: 26px;
  }
}

@media (width <= 698.98px) {
  .more .section__inner {
    flex-direction: column;
    gap: 60px;
    display: flex;
  }

  .more__title {
    margin-block-end: 14px;
  }

  .more__balmax {
    padding-inline-end: 0;
  }

  .more__evraz {
    padding-block-start: 0;
  }

  .more__evraz .pic {
    order: -1;
  }

  .more .pic {
    overflow: hidden;
  }

  .more .pic > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: 100%;
  }

  .more .pic.anim > * {
    opacity: 1;
    translate: 0;
  }

  .more .pic img {
    width: 100%;
  }

  .more .more__info {
    overflow: hidden;
  }

  .more .more__info > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: -100%;
  }

  .more .more__info.anim > * {
    opacity: 1;
    translate: 0;
  }

  .more__logo {
    height: 24px;
  }
}

.contacts {
  --pt: 80px;
}

.contacts .section__inner {
  padding-block-end: 0;
}

.contacts .section__title {
  top: calc(var(--pt)  + var(--section--pb));
}

.contacts__form {
  grid-area: 1 / 2 / -1 / 4;
  padding-top: 80px;
  position: relative;
}

.contacts__pic {
  grid-area: 1 / -3 / -1 / -1;
}

.contacts__pic img {
  object-fit: cover;
  height: 100%;
}

.contacts__logo {
  grid-area: 1 / -4 / -1 / -1;
  margin-top: auto;
  position: relative;
}

.contacts__logo img {
  width: 100%;
}

@media (width >= 699px) {
  .contacts__logo--mob {
    display: none !important;
  }
}

@media (width <= 1190.98px) {
  .contacts {
    --pt: 20px;
  }

  .contacts__form {
    grid-area: 1 / 1 / -1 / 3;
    padding-block-end: 150px;
    padding-inline: 0 30px;
  }

  .contacts__pic {
    grid-area: 1 / 3 / -1 / 5;
  }

  .contacts__logo {
    grid-area: 1 / 2 / -1 / 5;
  }
}

@media (width <= 698.98px) {
  .contacts .section__inner {
    flex-direction: column;
    padding-block-end: 0;
    display: flex;
  }

  .contacts__form {
    padding-block-end: 40px;
    padding-inline: 0;
  }

  .contacts__logo {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .contacts__pic {
    height: 600px;
  }

  .contacts__pic img {
    width: 100%;
  }

  .contacts__logo--desk {
    display: none !important;
  }

  .contacts__logo--mob {
    display: block !important;
  }
}

.form__fieldset {
  border: none;
  margin: 0;
  padding: 0 1px;
}

.form__legend {
  color: #2d2f33;
  text-transform: uppercase;
  margin-block-end: 40px;
}

.form__text {
  color: #2d2f33;
  margin-block-end: 50px;
  line-height: 1.5;
  font-size: clamp(16px, 1.6vw, 25px) !important;
}

.form__text a {
  color: #fff;
  background-color: #ed7817;
  margin-inline-start: 5px;
  padding: 1px 8px;
  padding-inline-start: 38px;
  display: inline-block;
  position: relative;
}

.form__text a:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" width=\"25\" height=\"25\" fill=\"none\"%3E%3Cpath fill=\"%23fff\" fill-rule=\"evenodd\" d=\"M13.217 2.876a.75.75 0 0 0-.832 0l-8.99 5.993a.745.745 0 0 0-.344.63v9.751a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V9.513a.747.747 0 0 0-.348-.646l-8.986-5.991Zm7.263 6.645L12.8 4.4l-7.678 5.12L11.405 14h2.793l6.282-4.48ZM4.55 10.956v7.548l5.32-3.757-5.32-3.791Zm6.854 4.544-5.309 3.75h13.413L14.2 15.5h-2.795Zm4.33-.753 5.317 3.756v-7.547l-5.317 3.791Z\" clip-rule=\"evenodd\"/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 8px;
  translate: 0 -50%;
}

@media (hover: hover) {
  .form__text a:hover {
    text-decoration: underline;
  }
}

.form__group {
  margin-block-end: 10px;
}

.form__input, .form__textarea {
  background-color: #fff;
  border: none;
  outline: none;
  width: 100%;
  padding-block: 18px;
  padding-inline: 23px;
  box-shadow: 0 6.4px 64px #0000000d;
}

.form__textarea {
  resize: none;
}

.form__button {
  color: #fff;
  background-color: #ed7817;
  border: none;
  justify-content: center;
  align-items: center;
  padding-block: 8px;
  padding-inline: 7%;
  font-size: clamp(16px, 1.6vw, 25px);
  line-height: 1;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .form__button:hover {
    color: #fff;
    background-color: #d66f15;
  }
}

.form__button:active {
  color: #fff;
  background-color: #d66f15;
}

@media (width <= 1190.98px) {
  .form__legend {
    margin-block-end: 26px;
  }

  .form__text {
    margin-block-end: 36px;
  }
}

@media (width <= 698.98px) {
  .form__button {
    padding-block: 8px;
    padding-inline: 25px;
    font-size: 18px;
  }
}

.checkbox {
  margin-block: 15px 20px;
}

.checkbox__label {
  cursor: pointer;
  width: 100%;
  padding-inline-start: 36px;
  display: block;
  position: relative;
}

@media (hover: hover) {
  .checkbox__label:hover:not(:has(a:hover)) .checkbox__box {
    background-color: #ed7817;
  }
}

.checkbox__box {
  top: 50%;
  background-color: #0000;
  border: 1px solid #ed7817;
  width: 25px;
  height: 25px;
  position: absolute;
  inset-inline-start: 0;
  translate: 0 -50%;
}

.checkbox__box:before {
  content: "";
  background-image: url("checkmark.60d8e3ff.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.checkbox__input:checked + .checkbox__box {
  background-color: #ed7817;
}

.checkbox__input:checked + .checkbox__box:before {
  display: block;
}

.checkbox__text {
  font-size: clamp(14px, 1.4vw, 20px);
  display: block;
}

.checkbox__text a {
  color: #ed7817;
}

@media (hover: hover) {
  .checkbox__text a:hover {
    text-decoration: underline;
  }
}

@media (width <= 698.98px) {
  .checkbox__label {
    padding-inline-start: 30px;
  }

  .checkbox__box {
    width: 22px;
    height: 22px;
  }
}

.footer {
  grid-template-columns: repeat(var(--columns), 1fr);
  border-top: 1px solid #dedede;
  padding-block: 26px;
  display: grid;
}

.footer__copyright {
  grid-area: 1 / 2 / 2 / 3;
}

.footer__social {
  grid-area: 1 / 4 / 2 / 5;
}

.footer__contacts {
  text-align: end;
  flex-direction: column;
  grid-area: 1 / -2 / 2 / -1;
  gap: 3px;
  display: flex;
}

.footer address, .footer p, .footer a {
  color: #b6b6b6;
  margin: 0;
  font-size: clamp(16px, 1.6vw, 22px);
  font-style: normal;
  line-height: 1;
}

@media (hover: hover) {
  .footer a:hover {
    color: #ed7817;
  }
}

@media (width <= 1400.98px) {
  .footer address, .footer p, .footer a {
    font-size: 18px;
  }
}

@media (width <= 1190.98px) {
  .footer__copyright {
    grid-area: 1 / 1 / 2 / 2;
  }

  .footer__social {
    grid-area: 1 / 3 / 2 / 4;
  }

  .footer__contacts {
    grid-area: 1 / 4 / 2 / -1;
  }
}

@media (width <= 698.98px) {
  .footer {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }

  .footer__social {
    justify-content: end;
    width: 50%;
    display: flex;
  }

  .footer__contacts {
    text-align: start;
    order: -1;
    width: 50%;
  }

  .footer__copyright {
    text-align: end;
    text-wrap: balance;
    order: 1;
    width: 100%;
    margin-top: 40px !important;
    margin-left: auto !important;
  }

  .footer address, .footer p, .footer a {
    font-size: 16px;
  }
}

.social__list {
  align-items: center;
  gap: 28px;
  display: flex;
}

.social__item {
  display: flex;
}

@media (hover: hover) {
  .social__link:hover svg path {
    fill: #ed7817;
  }
}

@media (width <= 1190.98px) {
  .social__list {
    gap: 16px;
  }
}

.header {
  transition: all .8s cubic-bezier(.68, -.55, .27, 1.55);
  translate: 0 -100px;
}

.header.anim {
  translate: 0;
}

.front .decor.anim .decor__item {
  opacity: 0;
}

.title > span {
  overflow: hidden;
}

.title > span span {
  opacity: 0;
  transition: all 1.1s ease-in-out;
  translate: 0 100%;
}

.title.anim > span span {
  opacity: 1;
  translate: 0;
}

.section__title {
  opacity: 0;
  transition: all 1.1s ease-in-out;
  translate: -80%;
}

.section__title.anim {
  opacity: 1;
  translate: 0;
}

.lead {
  opacity: 0;
  transition: all .6s ease-in-out;
  translate: 80%;
}

.lead:before {
  opacity: 0;
  transition: all .6s ease-in-out;
  translate: 0;
}

.lead.anim {
  opacity: 1;
  translate: 0;
}

.lead.anim:before {
  opacity: 1;
  translate: -100%;
}

.assets__item {
  overflow: hidden;
}

.assets__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.assets__item.anim > * {
  opacity: 1;
  translate: 0;
}

@media (width <= 698.98px) {
  .assets__item:nth-child(2n) {
    overflow: hidden;
  }

  .assets__item:nth-child(2n) > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: 100%;
  }

  .assets__item:nth-child(2n).anim > * {
    opacity: 1;
    translate: 0;
  }
}

.trust__item {
  overflow: hidden;
}

.trust__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.trust__item.anim > * {
  opacity: 1;
  translate: 0;
}

.trust__item.anim:before {
  translate: 0;
}

@media (width <= 698.98px) {
  .trust__item:nth-child(odd) {
    overflow: hidden;
  }

  .trust__item:nth-child(odd) > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: 100%;
  }

  .trust__item:nth-child(odd).anim > * {
    opacity: 1;
    translate: 0;
  }
}

.force__item {
  overflow: hidden;
}

.force__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.force__item.anim > * {
  opacity: 1;
  translate: 0;
}

@media (width <= 698.98px) {
  .force__item:nth-child(2n) {
    overflow: hidden;
  }

  .force__item:nth-child(2n) > * {
    opacity: 0;
    transition: translate 1s ease-in-out, opacity 1s ease-in-out;
    display: block;
    translate: 100%;
  }

  .force__item:nth-child(2n).anim > * {
    opacity: 1;
    translate: 0;
  }
}

.pic__wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pic__img {
  transition: all 1.1s ease-in-out .3s;
  translate: 5% -5%;
  scale: 1.1;
}

.pic__img--small {
  translate: 15% -15%;
}

.pic__decor {
  opacity: 0;
  transition: all 1.1s ease-in-out .3s;
  translate: 30% -30%;
}

.pic.anim .pic__img {
  translate: 0;
  scale: 1;
}

.pic.anim .pic__decor {
  opacity: 1;
  translate: 0;
}

.future__item {
  overflow: hidden;
}

.future__item:before, .future__item:after {
  transition: all .8s ease-in-out;
  translate: 100px;
}

.future__item > * {
  transition: all .8s ease-in-out;
}

.future__item.anim:before, .future__item.anim:after {
  translate: 0;
}

.future__item > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 100%;
}

.future__item.anim > * {
  opacity: 1;
  translate: 0;
}

.form__item:first-child:not(.anim-end) {
  transition-delay: 0s !important;
}

.form__item:nth-child(2):not(.anim-end) {
  transition-delay: .1s !important;
}

.form__item:nth-child(3):not(.anim-end) {
  transition-delay: .2s !important;
}

.form__item:nth-child(4):not(.anim-end) {
  transition-delay: .3s !important;
}

.form__item:nth-child(5):not(.anim-end) {
  transition-delay: .4s !important;
}

.form__item:nth-child(6):not(.anim-end) {
  transition-delay: .5s !important;
}

.form__item:nth-child(7):not(.anim-end) {
  transition-delay: .6s !important;
}

.decor:before {
  transition-delay: 0s;
}

.decor:after {
  transition-delay: calc((var(--columns)  + 1) * .2s);
}

.decor.anim:before {
  opacity: 0;
  translate: 10px;
}

.decor.anim:after {
  opacity: 0;
}

.decor.anim .decor__item:before {
  opacity: 0;
  translate: 40px;
}

.animLeft {
  overflow: hidden;
}

.animLeft > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: -100%;
}

.animLeft.anim > * {
  opacity: 1;
  translate: 0;
}

.animRight {
  overflow: hidden;
}

.animRight > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 100%;
}

.animRight.anim > * {
  opacity: 1;
  translate: 0;
}

.animBottom {
  overflow: hidden;
}

.animBottom > * {
  opacity: 0;
  transition: translate 1s ease-in-out, opacity 1s ease-in-out;
  display: block;
  translate: 0 100%;
}

.animBottom.anim > * {
  opacity: 1;
  translate: 0;
}
/*# sourceMappingURL=armax.dc641ec2.css.map */
