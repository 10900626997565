.front {
  position: relative;

  .section__inner {
    height: 100svh;
    padding-block: 0;
  }

  &__pic {
    position: absolute;
    z-index: -1;
    top: 0;
    left: calc(var(--p-inline) * -1);

    display: flex;

    width: calc(100% + (var(--p-inline) * 2));
    height: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__text {
    position: relative;
    z-index: 1;

    display: grid;
    grid-area: 2 / 1 / 3 / 5;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);

    margin-top: auto;
    padding-block-end: 0;
  }

  &__subtitle {
    position: relative;

    grid-area: 1 / 1 / 2 / 3;

    margin: auto 0 55px;

    font-size: clamp(38px, 3vw, 60px);
    line-height: 1;
    color: white;
    white-space: nowrap;

    span.orange {
      display: inline-block;
      padding-block: 3px;
      background-color: $color-orange;
    }
  }

  &__title {
    position: relative;

    display: flex;
    grid-area: 2 / 1 / 3 / -1;
    align-items: end;

    margin: 0;

    img {
      width: 100%;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__text {
      grid-area: 1 / 1 / 3 / 4;
      grid-template-columns: repeat(3, 1fr);
      margin: 0;
      padding: 0;
    }

    &__title {
      margin-block: auto 0;
    }

    &__subtitle {
      margin-block: 160px auto;
    }
  }
  @include tzar-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;
      padding-top: 100px;
    }

    &__subtitle {
      margin: 0;
    }

    &__title {
      margin: 0;
    }
  }
}
