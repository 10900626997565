.header {
  translate: 0 -100px;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  &.anim {
    translate: 0;
  }
}

.front {
  .decor.anim .decor__item {
    opacity: 0;
  }
}

.title {
  > span {
    overflow: hidden;

    span {
      translate: 0 100%;
      opacity: 0;
      transition: all 1.1s ease-in-out;
    }
  }

  &.anim > span span {
    translate: 0;
    opacity: 1;
  }
}

.section__title {
  translate: -80% 0;
  opacity: 0;
  transition: all 1.1s ease-in-out;

  &.anim {
    translate: 0;
    opacity: 1;
  }
}

.lead {
  translate: 80% 0;
  opacity: 0;
  transition: all 0.6s ease-in-out;

  &::before {
    translate: 0;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }

  &.anim {
    translate: 0;
    opacity: 1;

    &::before {
      translate: -100% 0;
      opacity: 1;
    }
  }
}

.assets {
  &__item {
    @include animLeft;
    @include tzar-breakpoint-down(sm) {
      &:nth-child(even) {
        @include animRight;
      }
    }
  }
}

.trust {
  &__item {
    @include animLeft;

    &.anim::before {
      translate: 0;
    }
    @include tzar-breakpoint-down(sm) {
      &:nth-child(odd) {
        @include animRight;
      }
    }
  }
}

.force {
  &__item {
    @include animLeft;
    @include tzar-breakpoint-down(sm) {
      &:nth-child(even) {
        @include animRight;
      }
    }
  }
}

.pic {
  &__wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__img {
    translate: 5% -5%;
    scale: 1.1;
    transition: all 1.1s 0.3s ease-in-out;

    &--small {
      translate: 15% -15%;
    }
  }

  &__decor {
    translate: 30% -30%;
    opacity: 0;
    transition: all 1.1s 0.3s ease-in-out;
  }

  &.anim .pic {
    &__img {
      translate: 0;
      scale: 1;
    }

    &__decor {
      translate: 0;
      opacity: 1;
    }
  }
}

.future {
  &__item {
    &::before,
    &::after {
      translate: 100px 0;
      transition: all 0.8s ease-in-out;
    }

    > * {
      transition: all 0.8s ease-in-out;
    }

    &.anim {
      &::before,
      &::after {
        translate: 0 0;
      }
    }
    @include animRight;
  }
}

.form {
  &__item {
    @for $i from 1 through 7 {
      &:nth-child(#{$i}):not(.anim-end) {
        transition-delay: #{($i - 1) * 0.1}s !important;
      }
    }
  }
}

.decor {
  &::before {
    transition-delay: 0s;
  }

  &::after {
    transition-delay: calc((var(--columns) + 1) * 0.2s);
  }

  &.anim {
    &::before {
      translate: 10px 0;
      opacity: 0;
    }

    &::after {
      opacity: 0;
    }

    .decor__item {
      &::before {
        translate: 40px 0;
        opacity: 0;
      }
    }
  }
}

// stylelint-disable-next-line selector-class-pattern
.animLeft {
  @include animLeft;
}

// stylelint-disable-next-line selector-class-pattern
.animRight {
  @include animRight;
}

// stylelint-disable-next-line selector-class-pattern
.animBottom {
  @include animBottom;
}
