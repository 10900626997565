@use '../../node_modules/normalize.css/normalize';
@use './ui';

@import './mixins';

/** Все миксины и повторяющиеся конструкции */
@import './vars';

/** Стили для евраза */

@include font-face(100, 'GTEestiProText');
@include font-face(200, 'GTEestiProText');
@include font-face(300, 'GTEestiProText');
@include font-face(400, 'GTEestiProText');
@include font-face(400, 'GTEestiProText');
@include font-face(700, 'GTEestiProText');
@include font-face(900, 'GTEestiProText');

html {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-size: $base_font-size;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;

  overflow-x: hidden;

  min-height: 100vh;
  margin: 0;
  padding: 0;
  padding-inline: var(--p-inline);

  font-family: GTEestiProText, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: black;

  background-color: white;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  border: 0;
}
