@use 'sass:map';
@use 'sass:math';
@use 'sass:list';
@use 'sass:string';

$prefix: 'tzar-';
$tzar-columns: 12 !default;
$tzar-gutter: 24px !default;

/** 1.5rem */

/** Именованный Массив Объектов (для сетки row > col) */
$tzar-breakpoints-grid: (
  /** первое значение: логическая заглушка */ mobile: 0,
  xs: 499px,
  sm: 699px,
  md: 889px,
  lg: 1191px,
  xl: 1401px,
  xxl: 1681px,
  ultra: 1921px
);

/** Именованный Массив Объектов (для медиа-запросов) */
$tzar-breakpoints-resp: (
  /** первое значение: логическая заглушка */ mobile: 0,
  xs: 399px,
  sm: 699px,
  md: 889px,
  lg: 1191px,
  xl: 1401px,
  xxl: 1681px,
  ultra: 1921px
);

/** Позволяет завести разные наборы переменных */
@mixin init-root-props($pr: $prefix, $map: $tzar-breakpoints-grid, $gt: $tzar-gutter) {
  @each $name, $value in $map {
    --#{$pr}breakpoint-#{$name}: #{$value};
  }

  --#{$pr}gutter: #{$gt};
}

:root {
  @include init-root-props;
  @include init-root-props('grid-', $tzar-breakpoints-resp);
}

.#{$prefix}row {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;

  box-sizing: border-box;

  /** Заметка: margin-top: calc(-1 * var(--#{$prefix}gutter-y)); */
  margin-right: calc(-0.5 * var(--#{$prefix}gutter, #{$tzar-gutter}));
  margin-left: calc(-0.5 * var(--#{$prefix}gutter, #{$tzar-gutter}));

  /** Отвязались от "голого класса" .tzar-col */
  & > * {
    /** чекнуть тему выравнивания контента
		Tzar old:
		display: flex;
		flex-direction: column;
	*/
    flex: 0 1 auto;
    padding-right: calc(0.5 * var(--#{$prefix}gutter, #{$tzar-gutter}));
    padding-left: calc(0.5 * var(--#{$prefix}gutter, #{$tzar-gutter}));
  }
}

.#{$prefix}col {
  flex: 1 0 0%;
}

@function br-next($name, $breakpoints: $tzar-breakpoints-grid, $breakpoint-names: map.keys($breakpoints)) {
  $n: list.index($breakpoint-names, $name);

  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if($n < list.length($breakpoint-names), list.nth($breakpoint-names, $n + 1), null);
}
@function br-min($name, $breakpoints: $tzar-breakpoints-grid) {
  /** Получить значение объекта из массива по именю */
  $min: map.get($breakpoints, $name);

  /** отбросить самый первый объект массива (заглушку */
  @return if($min !=0, $min, null);
}
@function br-max($name, $breakpoints: $tzar-breakpoints-grid) {
  /** Получить значение объекта из массива по именю */
  $max: map.get($breakpoints, $name);

  /** отбросить заглушку, а иное-валидное уменьшить на две сотых рх */
  @return if($max and $max > 0, $max - 0.02, null);
}
@function br-infix($name, $breakpoints: $tzar-breakpoints-grid) {
  $cur: br-min($name, $breakpoints);

  /** по логическому услвоию вернуть ИМЯ брейкпоинта или пустую строку */
  @return if($cur ==null, '', '-#{$name}');
}

@mixin tzar-breakpoint-grid($name, $breakpoints: $tzar-breakpoints-grid) {
  $max: br-max($name, $breakpoints);

  @if $max {
    $sublist: map.keys($breakpoints);

    /** проверили порядок объекта в массиве и перевели ПОСЛЕДНИЙ на медиа ВВЕРХ */
    $condition: list.index($sublist, $name) ==list.length($sublist);

    @if $condition {
      @media (min-width: $max) {
        @content;
      }
    } @else {
      @media (max-width: $max) {
        @content;
      }
    }
  } @else {
    @content;
  }
}
@mixin mk-col($size: false, $columns: $tzar-columns) {
  @if $size {
    flex: 0 0 auto;

    /** Номер текущей колонки / на количество колонок (приходит из перебора) */
    width: math.percentage(math.div($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}
@mixin mk-offset($size, $columns: $tzar-columns) {
  $num: math.div($size, $columns);

  margin-left: if($num ==0, 0, math.percentage($num));
}

/** Перебрать масств с именами: "mobile", "xs", … */
@each $point in map.keys($tzar-breakpoints-grid) {
  @include tzar-breakpoint-grid($point, $tzar-breakpoints-grid) {
    $infix: br-infix($point, $tzar-breakpoints-grid);

    /** Перебрать наши настраиваемые колонки */
    @for $i from 1 through $tzar-columns {
      /** по условию будет или префикс брейкпоинта или пустая строка */
      .#{$prefix}col#{$infix}-#{$i} {
        /** .tzar-col */
        @include mk-col($i, $tzar-columns);
      }
    }

    /** Перебрать колонки без последней, иначе будет занята вся строка */
    @for $i from 0 through ($tzar-columns - 1) {
      /** отбросить 0-вую заглушку и бесполезный tzar-offset-0 */
      @if not($infix == '' and $i ==0) {
        .#{$prefix}offset#{$infix}-#{$i} {
          /** .tzar-offset */
          @include mk-offset($i, $tzar-columns);
        }
      }
    }

    .no-gutter#{$infix} {
      --#{$prefix}gutter: 0;
    }
  }
}

/** Варианты брейкпоинтов для адаптива */
@mixin tzar-breakpoint-up($name, $breakpoints: $tzar-breakpoints-resp) {
  $min: br-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
@mixin tzar-breakpoint-down($name, $breakpoints: $tzar-breakpoints-resp) {
  $max: br-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
@mixin tzar-breakpoint-between($lower, $upper, $breakpoints: $tzar-breakpoints-resp) {
  $min: br-min($lower, $breakpoints);
  $max: br-max($upper, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max ==null {
    @include tzar-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min ==null {
    @include tzar-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}
@mixin tzar-breakpoint-only($name, $breakpoints: $tzar-breakpoints-resp) {
  $min: br-min($name, $breakpoints);
  $next: br-next($name, $breakpoints);
  $max: br-max($next, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max ==null {
    @include tzar-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min ==null {
    @include tzar-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}
@mixin font-face($weight, $family: $custom-font-family, $style: null) {
  $font-name: '#{$family}-#{$weight}';
  $font-style: 'normal';

  @if $style {
    $font-name: '#{$font-name}-#{$style}';
    $font-style: #{$style};
  }
  @font-face {
    font-family: string.quote($family);
    font-weight: #{$weight};
    font-style: #{$font-style};
    font-display: swap;
    src:
      url('../fonts/#{$family}/#{$font-name}.woff2') format('woff2'),
      url('../fonts/#{$family}/#{$font-name}.woff') format('woff');
  }
}
@mixin rem($property, $values) {
  $px: ();
  $rem: ();
  @each $value in $values {
    @if $value ==0 or $value ==auto or $value == '0px' {
      $px: list.append($px, $value);
      $rem: list.append($rem, $value);
    } @else {
      $unit: math.unit($value);

      @if $unit == 'px' {
        $px: list.append($px, $value);
        $rem: list.append($rem, (math.div($value, $base_font-size) + rem));
      }

      @if $unit == 'rem' {
        $px: list.append($px, (math.div($value * math.div($base_font-size, 1px), 1rem) + px));
        $rem: list.append($rem, $value);
      }
    }
  }

  @if $px ==$rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}
@mixin visually-hidden {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  border: 0;
}
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin animBase($translateX, $translateY) {
  overflow: hidden;

  & > * {
    translate: $translateX $translateY;
    display: block;
    opacity: 0;
    transition:
      translate 1s ease-in-out,
      opacity 1s ease-in-out;
  }

  &.anim > * {
    translate: 0;
    opacity: 1;
  }
}

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin animLeft {
  @include animBase(-100%, 0);
}

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin animRight {
  @include animBase(100%, 0);
}

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin animBottom {
  @include animBase(0, 100%);
}
