.more {
  &__title {
    grid-area: 1 / 2 / 2 / 5;
    margin-block-end: 80px;
  }

  &__balmax {
    grid-area: 2 / 2 / 3 / 4;

    .pic {
      position: relative;

      &::before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        translate: 0 -100%;

        width: 45%;
        height: 26px;

        background-color: $color-yellow;
      }
    }

    .pic,
    .more__info {
      @include animLeft;
    }
  }

  &__evraz {
    grid-area: 2 / -3 / 3 / -1;

    .pic,
    .more__info {
      @include animRight;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  &__link {
    font-size: clamp(16px, 2vw, 25px);
  }

  &__logo {
    height: 32px;

    img {
      height: 100%;
    }
  }

  .pic {
    position: relative;
  }
  @include tzar-breakpoint-down(lg) {
    &__title {
      grid-area: 1 / 1 / 2 / 3;
    }

    &__balmax {
      grid-area: 2 / 1 / 3 / 3;
      padding-inline-end: 50px;
    }

    &__evraz {
      grid-area: 2 / 3 / 3 / 5;
      padding-block-start: 16px;
    }

    &__logo {
      height: 26px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    .section__inner {
      display: flex;
      flex-direction: column;
      gap: 60px;
    }

    &__title {
      margin-block-end: 14px;
    }

    &__balmax {
      padding-inline-end: 0;
    }

    &__evraz {
      padding-block-start: 0;

      .pic {
        order: -1;
      }
    }

    .pic {
      @include animRight;

      img {
        width: 100%;
      }
    }

    .more__info {
      @include animLeft;
    }

    &__logo {
      height: 24px;
    }
  }
}
