.contacts {
  --pt: 80px;

  .section__inner {
    padding-block-end: 0;
  }

  .section__title {
    top: calc(var(--pt) + var(--section--pb));
  }

  &__form {
    position: relative;
    grid-area: 1 / 2 / -1 / 4;
    padding-top: 80px;
  }

  &__pic {
    grid-area: 1 / -3 / -1 / -1;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__logo {
    position: relative;
    grid-area: 1 / -4 / -1 / -1;
    margin-top: auto;

    img {
      width: 100%;
    }
  }
  @include tzar-breakpoint-up(sm) {
    &__logo--mob {
      display: none !important;
    }
  }
  @include tzar-breakpoint-down(lg) {
    --pt: 20px;

    &__form {
      grid-area: 1 / 1 / -1 / 3;
      padding-block-end: 150px;
      padding-inline: 0 30px;
    }

    &__pic {
      grid-area: 1 / 3 / -1 / 5;
    }

    &__logo {
      grid-area: 1 / 2 / -1 / 5;
    }
  }
  @include tzar-breakpoint-down(sm) {
    .section__inner {
      display: flex;
      flex-direction: column;
      padding-block-end: 0;
    }

    &__form {
      padding-block-end: 40px;
      padding-inline: 0;
    }

    &__logo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &__pic {
      height: 600px;

      img {
        width: 100%;
      }
    }

    &__logo--desk {
      display: none !important;
    }

    &__logo--mob {
      display: block !important;
    }
  }
}

.form {
  &__fieldset {
    margin: 0;
    padding: 0 1px;
    border: none;
  }

  &__legend {
    margin-block-end: 40px;
    color: $color-text;
    text-transform: uppercase;
  }

  &__text {
    margin-block-end: 50px;
    font-size: clamp(16px, 1.6vw, 25px) !important;
    line-height: 1.5;
    color: $color-text;
  }

  &__text a {
    position: relative;

    display: inline-block;

    margin-inline-start: 5px;
    padding: 1px 8px;
    padding-inline-start: 38px;

    color: white;

    background-color: $color-orange;

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 8px;
      translate: 0 -50%;

      width: 24px;
      height: 24px;

      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none"%3E%3Cpath fill="%23fff" fill-rule="evenodd" d="M13.217 2.876a.75.75 0 0 0-.832 0l-8.99 5.993a.745.745 0 0 0-.344.63v9.751a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V9.513a.747.747 0 0 0-.348-.646l-8.986-5.991Zm7.263 6.645L12.8 4.4l-7.678 5.12L11.405 14h2.793l6.282-4.48ZM4.55 10.956v7.548l5.32-3.757-5.32-3.791Zm6.854 4.544-5.309 3.75h13.413L14.2 15.5h-2.795Zm4.33-.753 5.317 3.756v-7.547l-5.317 3.791Z" clip-rule="evenodd"/%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    @include hover {
      text-decoration: underline;
    }
  }

  &__group {
    margin-block-end: 10px;
  }

  &__input,
  &__textarea {
    width: 100%;
    padding-block: 18px;
    padding-inline: 23px;

    background-color: white;
    border: none;
    outline: none;
    box-shadow: 0 6.4px 64px 0 rgb(0 0 0 / 5%);
  }

  &__textarea {
    resize: none;
  }

  &__button {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-block: 8px;
    padding-inline: 7%;

    font-size: clamp(16px, 1.6vw, 25px);
    line-height: 1;
    color: white;

    background-color: $color-orange;
    border: none;
    @include hover {
      color: white;
      background-color: #d66f15;
    }

    &:active {
      color: white;
      background-color: #d66f15;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__legend {
      margin-block-end: 26px;
    }

    &__text {
      margin-block-end: 36px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__button {
      padding-block: 8px;
      padding-inline: 25px;
      font-size: 18px;
    }
  }
}

.checkbox {
  margin-block: 15px 20px;

  &__label {
    cursor: pointer;

    position: relative;

    display: block;

    width: 100%;
    padding-inline-start: 36px;
    @include hover {
      &:not(:has(a:hover)) {
        .checkbox__box {
          background-color: $color-orange;
        }
      }
    }
  }

  &__box {
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    translate: 0 -50%;

    width: 25px;
    height: 25px;

    background-color: transparent;
    border: 1px solid $color-orange;

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;

      display: none;

      width: 100%;
      height: 100%;

      background-image: url('../images/icons/checkmark.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__input:checked + .checkbox__box {
    background-color: $color-orange;

    &::before {
      display: block;
    }
  }

  &__text {
    display: block;
    font-size: clamp(14px, 1.4vw, 20px);

    a {
      color: $color-orange;
      @include hover {
        text-decoration: underline;
      }
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__label {
      padding-inline-start: 30px;
    }

    &__box {
      width: 22px;
      height: 22px;
    }
  }
}
