.trust {
  &__header {
    position: relative;
    grid-area: 1 / 2 / 2 / -1;
    margin-block-end: 15px;
  }

  &__pic {
    display: block;
    grid-area: 5 / 1 / 5 / -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__list {
    display: grid;

    &:nth-of-type(1) {
      grid-area: 2 / 2 / 3 / -2;
      grid-template-columns: repeat(4, 1fr);
      margin-block-end: 10px;
    }

    &:nth-of-type(2) {
      grid-area: 3 / 2 / 4 / 5;
      grid-template-columns: repeat(3, 1fr);
      margin-block-end: 60px;
    }
  }

  &__item {
    --i-size: 57px;

    position: relative;

    padding-block: 30px;
    padding-inline: 20px;

    font-size: clamp(16px, 1.6vw, 22px);

    background-color: white;
    border-inline: 1px solid rgba(var(--color-gray), 0.5);

    &-inner {
      position: relative;
    }

    &-logo {
      height: 35px;
      margin-block-end: 25px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    &-value {
      margin-block-end: 5px;
      font-size: clamp(16px, 1.6vw, 22px);
      font-weight: 600;
    }

    &-text {
      margin-block-end: 0;
      font-size: clamp(14px, 1.6vw, 20px);
    }

    &-icon {
      margin-block-end: 30px;
    }
  }
  @include tzar-breakpoint-down(xl) {
    &__pic {
      height: 450px;
    }

    &__item {
      padding-block: 20px;
      padding-inline: 15px;
    }
  }
  @include tzar-breakpoint-down(lg) {
    &__header {
      grid-area: 1 / 1 / 2 / -1;
    }

    &__list {
      &:nth-of-type(1) {
        grid-area: 2 / 1 / 3 / -1;
        grid-template-columns: repeat(4, 1fr);
        margin-block-end: 10px;
      }

      &:nth-of-type(2) {
        grid-area: 3 / 1 / 4 / 4;
        grid-template-columns: repeat(3, 1fr);
        margin-block-end: 60px;
      }
    }

    &__item {
      &-icon {
        height: 50px;

        img {
          height: 100%;
        }
      }
    }
  }
  @include tzar-breakpoint-down(md) {
    &__pic {
      height: 360px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &__list {
      &:nth-of-type(1) {
        grid-area: 2 / 1 / 6 / 2;
        grid-template-columns: repeat(1, 1fr);
        margin-block-end: 20px;
      }

      &:nth-of-type(2) {
        grid-area: 2 / 2 / 5 / 3;
        grid-template-columns: repeat(1, 1fr);
        margin-block-end: 20px;
      }
    }

    &__item {
      padding-block: 15px;
      padding-inline: 10px;

      &-logo {
        height: 25px;
        margin-block-end: 20px;
      }

      &-icon {
        margin-block-end: 20px;
      }

      &-text {
        width: 90%;
      }
    }

    &__pic {
      grid-area: 6 / 1 / 7 / -1;
    }
  }
}

.trust__title span {
  &:nth-child(2) {
    translate: 80px -7px;
  }

  &:nth-child(3) {
    translate: 160px -14px;
  }
  @include tzar-breakpoint-down(lg) {
    &:nth-child(2) {
      translate: 20px -5px;
    }

    &:nth-child(3) {
      translate: 40px -10px;
    }
  }
  @include tzar-breakpoint-down(sm) {
    &:nth-child(2) {
      translate: 8px -5px;
    }

    &:nth-child(3) {
      translate: 16px -10px;
    }
  }
}
